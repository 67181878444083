import React, { useState } from "react"

import { Link, useParams } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import {VerifyUserComponent} from "ppm-react-authentication";
import {USERS_API_URL} from "../../helpers/api-urls";
//transalation
import { withTranslation } from "react-i18next";
// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"

const ConfirmMail = (props) => {
  const {code} = useParams()

  const [isOk, setIsOk] = useState(false)
  const [msg, setMsg] = useState("EMAIL_VERIFY_SUCCESS")

  const handleResponse = (e)=>{
    if(e.code !=200){
      if (e.data.message == "Email already verified" )
        setMsg("EMAIL_ALREADY_VERIFIED")
      
      if (e.data.message == "Unknown user")
        setMsg("UNKNOWN_USER")
      
    }
    setIsOk(true)
  }
  return (
    <React.Fragment>
        <title>Confirm Mail | LGVP</title>
      <div className="account-pages my-5 pt-sm-5">

        <Container>
          <Row>
            <VerifyUserComponent 
              apiUrl={USERS_API_URL}
              code={code}
              onVerifySuccess={(e)=>{handleResponse(e)}}
            />
            <Col lg={12}>
              <div className="text-center mb-5 text-muted">
                <Link to="dashboard" className="d-block auth-logo">
                  <img
                    src={logodark}
                    alt=""
                    height="60"
                    className="auth-logo-dark mx-auto"
                  />
                  <img
                    src={logolight}
                    alt=""
                    height="60"
                    className="auth-logo-light mx-auto"
                  />
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bx-mail-send h1 mb-0 text-primary"></i>
                        </div>
                      </div>
                      {
                        isOk ? (
                      
                        <div className="p-2 mt-4">
                          <h4>{props.t("SUCCESS")} !</h4>
                          <p className="text-muted"> {props.t(msg)}</p>
                          <div className="mt-4">
                            <Link to="/login" className="btn btn-success">
                            {props.t("LOGIN")}
                            </Link>
                          </div>
                        </div>
                        ) : <p>{props.t("VERIFICATION")} ...</p>
                      }
                    </div>
                  </div>
                </CardBody>
              </Card>
              {/*
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger"></i> by Themesbrand
                </p>
              </div>
            */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

//export with translation
export default withTranslation()(ConfirmMail)
//export default ConfirmMail
