/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import {useParams,useNavigate} from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Form,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Label,
     Alert
} from "reactstrap"

import LoadingContainer from "../Utility/Helpers/LoadingContainer";
import {DIALOG_TYPE, HTTP_RESPONSE} from "../Utility/Helpers/constants";
import "../Utility/Helpers/style.css";
import SupplierRepository from "./Repository/supplier-repository";
import UsersRepository from "pages/Users/repository/users-repository";
import { withTranslation } from "react-i18next";
//import AutocompleteSelectType from "../form/core/type/AutocompleteSelectType";
import AutocompleteSelectType from "helpers/Form/AutocompleteSelectType";
import {useFormik} from "formik";

const PagesAssignSupplier  = (props) =>  {
    /**
     *
     * @param props
     */
     const {history} = props;

     const [loading, setLoading] = useState(false);
     const [error, setError] = useState("");
     const [dialog, setDialog] = useState({
         show: false,
         onConfirm: () => {
         },
         handleClose: () => {
         },
         message: "",
         type: DIALOG_TYPE.ALERT
     });
     const [enabled, setEnabled] = useState(false);
     const [user,setUser] =useState([]);
     const params = useParams();
     const {id = 0} = params;
     const [initialsuppliers, setInitialSupplier] = useState([]);
     const [cieId,setCieId] = useState();
     const [ucsupplier, setUscSupplier] = useState([]);
     const VENDOR_ROLE_RENS_ID= process.env.REACT_APP_VENDOR_ROLE_RENS_ID;
     const VENDOR_ROLE_MONDOU_ID = process.env.REACT_APP_VENDOR_ROLE_MONDOU_ID;
     const navigate = useNavigate();
     const onEditSuccess = (response) => {
        setLoading(false);
        navigate("/supplier");
    }

    const toogleEnable = ({target}) => {
      setEnabled(!enabled);
    };
    
    const items = JSON.parse(localStorage.getItem('authUser'));
      
    const validation = useFormik({
       enableReinitialize: true,
      initialValues: {
        enabled: 1,
        supplier : []
      },
      onSubmit: (values) => {

        setLoading(true)

        
        
        
        let tmp = values;
        
        let added_supplier = values.supplier.filter( (value, index, arr) => {
          return initialsuppliers.indexOf(value) == -1;
        });

        let deleted_supplier = initialsuppliers.filter( (value, index, arr) => {
          return values.supplier.indexOf(value) == -1;
        });
        
        const user_company_id = user.user_company_id;
        delete user.user_company_id;
        console.log(VENDOR_ROLE_MONDOU_ID);
        console.log(VENDOR_ROLE_RENS_ID);
        
        if (user.enabled == 0)
        {
          //add role to user company depending if user come from mondou or rens
          if (cieId == 1)
          {
            //find role by name mondou and add it to user company
            UsersRepository.findRoleByName('MONDOU_VENDOR').then((role) => { 
              console.log(role) ;
              UsersRepository.flush({userCompanyId:user_company_id , roleId : role.id}).then( (response) => { 
                console.log(response) ;
              }).catch(response => {
                catchError(response)
              });
            })
            

          }
          else
          {
            UsersRepository.findRoleByName('REN_PET_VENDOR').then((role) => { 
              console.log(role) ;
              UsersRepository.flush({userCompanyId:user_company_id , roleId : role.id}).then( (response) => { 
                console.log(response) ;
              }).catch(response => {
                catchError(response)
              });
            })
          }

        }
        user.enabled = 1;
        deleted_supplier.forEach(e => {
          UsersRepository.delsupplier(user_company_id, e.id);
        });

        added_supplier.forEach(e => {
          SupplierRepository.add({userCompanyId: user_company_id, supplierId: e.id});
        });
        UsersRepository.edit(user);
        setLoading(false);
        if (typeof onEditSuccess == 'function') {
          onEditSuccess(user);
        }
      }
    
    });

     /**
   * Gestion de la soumission du formulaire
   *
   * @param event
   * @returns {Promise<void>}
   */
  const handleSubmitForm = (event) => {
    event.preventDefault();
    validation.handleSubmit();
  };


  useEffect(() => {
    if (id) {
      setLoading(true);
      const items = JSON.parse(localStorage.getItem('authUser'));
      if (items) {
        //getUser(items.id); 
        setCieId(items.companyId);
        UsersRepository.findwithCie(id,items.companyId).then((u) => {
          setEnabled(u.enabled === 1);
          setUser(u);
          // validation.setValues(u);
          UsersRepository.findSupplier(u.user_company_id).then((suppliers) =>{
            //validation.setValues(suppliers);
            setInitialSupplier(suppliers);
            validation.setValues({
              ...validation.values,
              supplier: suppliers
            });
           
            setLoading(false);
          }) 
        })
      }
    } else {
      setLoading(false);
    }
  }, [cieId]);

  

  
    return (

        <>
        <div className="page-content">
            <title>Assign</title>
        <Container fluid={true}>
        <LoadingContainer className="mb-4" loading={loading}>
        <Row>
          <Col className="col-6">
            <Card>
              <CardBody>
                <CardTitle>{props.t("ASSIGN_SUPPLIER_FOR")}  {user.firstname} {user.lastname}/{user.companyName}</CardTitle>
                <CardSubtitle className="mb-3">
                  {props.t("PLEASE_FILL_DETAIL")}
                </CardSubtitle>

                <Form onSubmit={(e) => handleSubmitForm(e)}>
                  {error.length ? (
                    <Alert color="danger">
                      {error}
                    </Alert>
                  ) : null}
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <div className="mt-4">
                        <CardTitle>{props.t("STATUS")}</CardTitle>
                       
                        <div className="mb-3">
                          <Label
                              className="form-label">{props.t("SUPPLIER")}</Label>
                          <AutocompleteSelectType
                              id={"supplier"}
                              name={"supplier"}
                              multiple={true}
                              choiceAttr={"nom"}
                              value={validation.values.supplier || []}
                              remoteChoices={(inputValue) => {return new Promise(resolve => resolve(SupplierRepository.findAll( 10,1,items.companyId, inputValue).then((data) => data)))}}
                              placeholder={props.t("CHOOSE_SUPPLIER")}
                              onChange={(e) => validation.handleChange(e)}
                              defaultCss={""}
                            />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary">
                      {props.t("SAVE_MODIFICATIONS")}                    
                    </button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        </LoadingContainer>
        </Container>
        </div>
        </>












     
    )
}
export default withTranslation()(PagesAssignSupplier);