import axios from "axios";
import {HTTP_RESPONSE} from "../../Utility/Helpers/constants";
import UserProvier from "../../../security/UserProvier";
import AuthorizationChecker from "../../../security/AuthorizationChecker";
import { REFERENCE_VALUES_API_URL } from "../../../helpers/api-urls";

UserProvier.setToken();
export const queries = (search="",associatedReferenceId=0,cieId = 1) => {
    
        return (
            REFERENCE_VALUES_API_URL+'?filter[where][or][0][code][like]=%'+search+'%' +'&filter=[where][code][options]=i' +
          '&filter[where][or][1][description][like]=%'+search+'%' +'&filter=[where][description][options]=i' +
          '&filter[include][]=company&filter[include][]=reference'+
          '&filter[where][companyId]=' + cieId +
          (associatedReferenceId ? '&filter[where][and][0][referenceId]='+associatedReferenceId : '' )
        )
      }
const ReferenceValueRepository = {

  getAll: async (search = "", associatedReferenceId=0,cieId = 1) => {
    try {
      const response = await axios.get(queries(search, associatedReferenceId, cieId));
      return response.data;
    } catch (e) {
      if (e.response) {
        throw e.response;
      } else {
        throw { status: HTTP_RESPONSE.HTTP_NOT_FOUND };
      }
    }
  }
    
};

export default ReferenceValueRepository;