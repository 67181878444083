import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from "react-router-dom";

const NonAuthLayout = (props) => {
  // const location = useLocation();

  return (
    <React.Fragment>
      {React.Children.map(props.children, child => {
        return React.isValidElement(child) ? child : null;
      })}
    </React.Fragment>
  );
}

NonAuthLayout.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object
}

export default NonAuthLayout;
