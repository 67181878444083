import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { LogoutUserComponent } from 'ppm-react-authentication'
import UserProvier from "security/UserProvier";
import { logoutUser } from "../../store/actions"

//redux
import { useDispatch } from "react-redux"
import axios from "axios"

const Logout = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(logoutUser(navigate))
    localStorage.clear();
    UserProvier.setToken()
    delete axios.defaults.headers['Authorization']
    delete axios.defaults.headers.common['Authorization']
  }, [dispatch, navigate])

  return <>
    <LogoutUserComponent/>
  </>
}

Logout.propTypes = {
  history: PropTypes.object,
}

export default Logout
