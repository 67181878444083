/* eslint-disable react/no-is-mounted */
import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";

//Lightbox
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { withTranslation } from "react-i18next";
import "./custom.css";



class LightBoxComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isFits: false,
      isEffects: false,
      isGallery: false,
      isGalleryZoom: false,
      isOpen: false,
      isOpen1: false,
      modal_standard: false,
    };
    this.openModal = this.openModal.bind(this);
    this.openModal1 = this.openModal1.bind(this);
    this.tog_standard = this.tog_standard.bind(this);
  }

  tog_standard() {
    // eslint-disable-next-line react/no-is-mounted
    this.setState(prevState => ({
      modal_standard: !prevState.modal_standard,
    }));
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  openModal1() {
    this.setState({ isOpen1: true });
  }

  render() {

    //meta title
   
    const { photoIndex } = this.state;
    const {images,showDelBtn,HandleDelBtn} = this.props;

    return (
      <React.Fragment>

            {this.state.isFits ? (
              <Lightbox
                mainSrc={images[1]}
                enableZoom={false}
                imageCaption={
                  ""
                }
                onCloseRequest={() => this.setState({ isFits: false })}
              />
            ) : null}

            {this.state.isEffects ? (
              <Lightbox
                mainSrc={images[2]}
                enableZoom={false}
                onCloseRequest={() => this.setState({ isEffects: false })}
              />
            ) : null}

            {this.state.isGallery ? (
              <Lightbox
                mainSrc={images[photoIndex].link}
                nextSrc={images[(photoIndex + 1) % images.length].link}
                prevSrc={
                  images[(photoIndex + images.length - 1) % images.length].link
                }
                enableZoom={false}
                onCloseRequest={() => this.setState({ isGallery: false })}
                onMovePrevRequest={() =>
                  this.setState({
                    photoIndex:
                      (photoIndex + images.length - 1) % images.length,
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + 1) % images.length,
                  })
                }
                imageCaption={"Project " + parseFloat(photoIndex + 1)}
              />
            ) : null}

            {this.state.isGalleryZoom ? (
              <Lightbox
                mainSrc={imageZoom[photoIndex]}
                nextSrc={imageZoom[(photoIndex + 1) % imageZoom.length]}
                prevSrc={
                  imageZoom[(photoIndex + imageZoom.length - 1) % imageZoom.length]
                }
                onCloseRequest={() => this.setState({ isGalleryZoom: false })}
                onMovePrevRequest={() =>
                  this.setState({
                    photoIndex:
                      (photoIndex + imageZoom.length - 1) % imageZoom.length,
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + 1) % imageZoom.length,
                  })
                }
              />
            ) : null}

            <Row>
              

              <Col>
                <Card>
                  <CardBody>
                    
                    <div className="popup-gallery d-flex flex-wrap">
                    {this.props.images.map((image, index) => (
                      <div className="img-fluid" key={index}>
                        <div className="image-container">
                          <img
                            src={image.link}
                            onClick={() =>
                              this.setState({ isGallery: true, photoIndex: index })
                            }
                            alt=""
                            width="150"
                          />
                          {showDelBtn && (
                            <button
                              type="button"
                              className="btn btn-danger w-100"
                              width="150"
                              onClick={() => {
                                HandleDelBtn(image.name);
                              }}
                            >
                              <i className="bx bx-no-entry"></i>
                              {this.props.t("REMOVE_THIS_PIC")}
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

      </React.Fragment>
    );
  }
}

export default withTranslation()(LightBoxComponent);
