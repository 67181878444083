import React, { useEffect,useState,useMemo } from "react";
import { ReferenceListComponent } from "ppm-react-references";
import { REFERENCES_API_URL } from "../../helpers/api-urls";
import { Link } from "react-router-dom";
import AuthorizationChecker from "security/AuthorizationChecker";
import { useTranslation,withTranslation } from "react-i18next";
import PropTypes from 'prop-types';  
import ReferenceRepository from "./Repository/reference-repository";
import TableContainer from "../Utility/Helpers/TableContainer";


const PagesReferences = (props) => {
  
    
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    const [items, setItems] = useState([]);
    const { t } = useTranslation();
    const ActionsFormatter = (props) => {
        const {
            item,
            onDelete = {},
            path = "",
            Link,
            showEdit = true, showView = true, showDelete = true
        } = props;
        return (
            <div className="bx-pull-right file-system">
                {
                    showView ?  <Link className="btn" to={ path + '/' + item.id + '/detail'}> <i className="bx bxs-show action-icon"/> </Link> 
                    : ""
                }
                {
                    showEdit ?  <Link className="btn" to={ path + '/' + item.id + '/edit'}> <i className="bx bxs-edit action-icon"/> </Link> 
                    : ""
                }
                {
                    showDelete ? <Link style={{marginLeft: "15px"}} className="btn" to="#" onClick={((event) => onDelete(event, item))}> <i className="bx bx-trash action-icon"/> </Link>
                    : ""
                }
            </div>
        )
    }


    useEffect(() => {
        //get references from API
        async function getReferences() {
            const response = await ReferenceRepository.getAll(authUser.companyId);
            setItems(response.items);
        }
        getReferences();
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: "id",
                accessor: 'id',
                width: 100,
                disableFilters: true
            },
          {
            Header: t('NAME'),
            id: 'name',
            accessor: 'name',
          },
          {
            Header: t('CODE'),
            id: 'code',
            accessor: 'code',
          },
          {
            Header: t('DESCRIPTION'),
            id: 'description',
            accessor: 'description',
          },
          {
            Header: t('IS_ACTIVE'),
            id: 'isActive',
            accessor: 'isActive',
            Cell: ({ value }) => (value && value === 1  ? <span className="badge bg-success p-2">{props.t("ACTIVE")}</span>
            : <span className="badge bg-danger p-2">{props.t("INACTIVE")}</span>),
          },
          {
            Header: t('VALUES_ASSOCIATED'),
            id: 'values',
            Cell: ({ row }) => (
              <Link style={{marginLeft: "15px"}} className="btn" to={"/pages-references/" + row.original.id}>
                <i className="bx bxs-show action-icon"/>
              </Link>
            ),
          },
          {
            Header: t('PARENT'),
            id: 'parentReference.name',
            accessor: 'parentReference.name',
          },
          {
            Header: '',
            id: 'actions',
            Cell: ({ row }) => (
              <ActionsFormatter
                item={row.original}
                onDelete={(event, elt) => props.handleDelete(event, elt)}
                path="/pages-references"
                Link={Link}
                showEdit={true}
                showView={true}
                showDelete={false}
              />
            ),
          },
        ],
        [t, props]
      );

    return (
        <div className="page-content">
            <title>{props.t("REFERENCES")}</title>
            <TableContainer
                columns={columns}
                data={items}
                isGlobalFilter={false}
                isAddOptions={false}
                isCustomPageSize={true}
                customPageSize={10}
                isPagination={true}
            />
        </div>
    );
};


PagesReferences.propTypes = {
    t: PropTypes.func,
};
//export default PagesReferences;
export default withTranslation()(PagesReferences);