/* eslint-disable react/no-is-mounted */
import React, {Fragment} from "react"
import {Row, Col, Card, CardBody, BreadcrumbItem, Input, Button} from "reactstrap"
import ProductRepository from "./Repository/product-repository"
import ProjectsRepository from "../Projects/Repository/project-repository"
import LoadingContainer from "../Utility/Helpers/LoadingContainer"
import {DIALOG_TYPE, HTTP_RESPONSE} from "../Utility/Helpers/constants"
import DialogBoxComponent from "../Utility/Helpers/DialogBoxComponent"
import "../Utility/Helpers/style.css"
import ActionsFormatter from "../Utility/Helpers/ActionsFormatter"
import PaginationWrap from "../Utility/Helpers/PaginationWrap"
import { withTranslation } from "react-i18next"
import {Link} from 'react-router-dom'
import AuthorizationChecker from "../../security/AuthorizationChecker"

import CellValue from "./Widget/CellValue"
import CellEditor from "./Widget/CellEditor"
import ImportProductModalPage from "./Widget/ImportProductModalPage"


import UserProvier from "../../security/UserProvier";
import moment from "moment-timezone";
class PagesProducts extends React.Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props)
        this.abortController = new AbortController();
        moment.tz.setDefault("UTC")
        this.state = {
            seed:0, //Use to force UI rerendering
            fields: [],
            projectStatus : {},
            items: [],
            selectedProductsIds: [],
            validationErrors: null,
            total: 0,
            entity: null, // element courant
            project: null,
            itemPerpage: 10,
            loading: true,
            loadingSearch: false,
            query: "",
            showConfirmDialog: false,
            showImportPage:false,
            companyId: UserProvier.get().companyId,
            pageSizeOptions: {
                totalSize: 0, // replace later with size(customers),
                custom: true,
                onPageChange: (page) => this.onPageChange(page),
                onSearchChange: this.handleSearchChange,
                page: 1,
                sizePerPage: 10,
                pageStartIndex: 1,
                sizePerPageList: 10,
                showTotal: true,
                onSizePerPageChange: {}
            },
            dialog: {
                show: false,
                onConfirm: () => {
                },
                handleClose: () => {
                },
                message: "",
                type: DIALOG_TYPE.ALERT
            },
        }
        this.onPageChange = this.onPageChange.bind(this)
        this.handleSearchChange = this.handleSearchChange.bind(this)
        this.handleDelete = this.handleDelete.bind(this)

        this.selectAllProducts = this.selectAllProducts.bind(this)
        this.selectProduct = this.selectProduct.bind(this)

        this.validateSelectedProducts = this.validateSelectedProducts.bind(this)
        this.clearValidationErrors = this.clearValidationErrors.bind(this)

        this.hideImportPage = this.hideImportPage.bind(this)
        this.showImportPage = this.showImportPage.bind(this)
        this.niveau = [
           "niveau1Id",
           "niveau2Id",
           "niveau3Id",
           "niveau4Id",
           "niveau5Id",
           "niveau6Id",
           "groupeMarchandise"
       ]

       ProjectsRepository.getStatus().then(data=>{
        console.log("Status:",data)
        this.setState((state)=>({...state, projectStatus: data, CORRECT_STATUS: data["CORRECT_STATUS"]}))
    }).catch(response=>{
        console.log("Failed loading status : ", response)
    })

   
    }

    componentDidMount() {
        this.findAll()
    }

    componentWillUnmount() {
        // Annuler toutes les requêtes en cours
    if (this.abortController) {
        this.abortController.abort();
    }
        this.setState = (state, callback) => {
            return;
        };
    }
    onPageChange(page ) {
        this.setState(
            (state) => ({
                ...state,
                loading: true,
                page: page,
            }),
            () => {
                this.findAll(page)
            }
        )
    }

    handleDelete(e, elt) {
        e.preventDefault()
        this.setState(
            (state ) => ({
                ...state,
                entity: elt,
                dialog: {
                    ...state.dialog,
                    handleConfirm: () => this.performDelete(),
                    handleClose: () => this.closeDialogModal(),
                    type: DIALOG_TYPE.CONFIRM_DELETION
                }
            }),
            () => {
                this.toggleDialogModal()
            }
        )
    }

    handleSearchChange(query) {}

    findAll(page = 1) {
        this.setState((state) => ({...state, loading: false, loadingSearch: false}))
        ProductRepository.findAll(page, this.state.itemPerpage, this.state.query, this.props.projectId).then((data) => {
            ProjectsRepository.find(this.props.projectId).then( (project) =>{
                this.setState(
                    (state) => ({
                        ...state,
                        fields: data.fields.map((field)=>{
                            return {
                                ...field,
                                show : true,
                                enableEdit : false
                            }
                        }),
                        items: data.items.map(item=>{

                            data.fields.filter(field=> field.type=="object").forEach(field=>{
                                let column = field.columnId.replace("Id","")
                                if (item[field.columnId]) {
                                    item[field.columnId] = item[column]
                                }
                                delete item[column]
                            })
                            let currentTZ = new Date().getTimezoneOffset()
                            if(item.dateLivraisonPossible && item.dateLivraisonPossible.includes("T")){
                                console.log("dateLivraisonPossible", item.dateLivraisonPossible)
                                item.dateLivraisonPossible = moment(item.dateLivraisonPossible).add(currentTZ, 'minutes').format()
                                console.log("dateLivraisonPossible", item.dateLivraisonPossible)
                            }
                            if(item.availableToShipToRen && item.availableToShipToRen.includes("T")){
                                item.availableToShipToRen = moment(item.availableToShipToRen).add(currentTZ, 'minutes').format()
                            }

                            return {
                                ...item,
                                enableEdit : false
                            }
                        }),
                        total: data.totalCount,
                        pageSizeOptions: {
                            sizePerPage: this.state.itemPerpage,
                            totalSize: 10, // replace later with size(customers),
                            custom: true,
                            onPageChange: (page) => this.onPageChange(page),
                            onSearchChange: this.handleSearchChange
                        },
                        project: project,
                    }),
                    () => {
                        this.setState((state) => ({...state, loading: false, loadingSearch: false, seed: state.seed+1}))
                    }
                )
            })
        }).catch((response) => {
            this.catchError(response)
        })
    }

    handleTableChange = (type, {searchText, page, sizePerPage, filters, sortField, sortOrder, cellEdit}) => {
        if (type === 'pagination') {
        } else if (type === 'sort') {

        } else if (type === 'filter') {

        } else if (type === 'search') {
            this.setState(
                (state) => ({
                    ...state,
                    query: searchText
                }),
                () => {
                    this.findAll()
                }
            )
        }
    }

    toggleDialogModal = () => {
        this.setState((state) => ({
            ...state,
            loading: false,
            dialog: {...state.dialog, show: !state.show}
        }))
    }

    closeDialogModal = () => {
        this.setState((state) => ({
            ...state,
            loading: false,
            dialog: {...state.dialog, show: false}
        }))
    }

    performDelete = () => {
        console.log("performDelete", this.state.entity.id)
        if (this.state.entity.id) {
            this.setState(
                (state) => ({
                    ...state,
                    loading: true,
                }),
                () => {
                    const idToRemove = this.state.entity.id
                    ProductRepository.remove(this.state.entity.id).then((data) => {
                        this.setState((state) => ({
                            ...state,
                            loading: false,
                            id: null,
                            total: state.total - 1,
                            items: state.items.filter((elt) => elt.id !== idToRemove)
                        }))
                        this.closeDialogModal()
                    }).catch((response) => {
                        this.catchError(response) 
                        this.closeDialogModal()
                    })
                }
            )
        }
    }

    catchError = (response, customHandleDialogClose=null) => {
        const dialog = {
            ...this.state.dialog,
            show: true,
            onConfirm: () => {customHandleDialogClose ? customHandleDialogClose() : this.closeDialogModal()},
            handleClose: () => {customHandleDialogClose ? customHandleDialogClose() : this.closeDialogModal()},
            handleConfirm: () => {customHandleDialogClose ? customHandleDialogClose() : this.closeDialogModal()},
            type: DIALOG_TYPE.ALERT
        }

        switch (response.status) {
            case HTTP_RESPONSE.HTTP_BAD_REQUEST: {
                dialog.type = DIALOG_TYPE.ALERT
                break
            }
            case HTTP_RESPONSE.HTTP_NOT_FOUND: {
                dialog.type = DIALOG_TYPE.ALERT
                if (response.data.message) {
                    dialog.message = response.data.message;
                }else if(response.data.error && response.data.error.message){
                    dialog.message = response.data.error.message;
                }
                break
            }
            case HTTP_RESPONSE.HTTP_NOT_ACCEPTABLE : {
                dialog.type = DIALOG_TYPE.NOT_ACCEPTABLE
                dialog.message = response.data.error.message
                break
            }
            case HTTP_RESPONSE.HTTP_UNAUTHORIZED: {
                dialog.type = DIALOG_TYPE.UNAUTHORIZED;

                try{
                    const err = JSON.parse(response.data.error.message)
                    if(err.message){
                        dialog.message = this.props.t(err.message,{name : err.name})
                    }else{
                        throw "Parse Error"
                    }
                }catch(e){
                    dialog.message = response.data.error.message 
                }
                break;
            }
            case HTTP_RESPONSE.HTTP_UNPROCESSABLE_ENTITY: {
                dialog.type = DIALOG_TYPE.SERVER_ERROR
                if(response.data){
                  if( response.data.error.code=="ER_DUP_ENTRY"){
                    dialog.message = props.t("PRODUCT_ALREADY_EXIST");
                  }else{
                    dialog.message = props.t("CHECK_FORM_FEILDS");
                  }
                }else{
                    dialog.message = response.message
                }
                break
            }
            case HTTP_RESPONSE.HTTP_INTERNAL_SERVER_ERROR: {
                let message = ''
                if (response.data.message) {
                    message = response.data.message
                }
                dialog.type = DIALOG_TYPE.SERVER_ERROR
                break
            }
            default:
                dialog.message=this.props.t("SERVEUR_UNREACHABLE")
        }
        this.setState((state) => ({
            ...state,
            dialog: dialog,
        }))
    }

    handleFormInputChange = ({target} ) => {
        const {name, value} = target
        this.setState(
            (state) => ({
                ...state,
                [name]: value,
                loadingSearch: true
            }), () => {
                this.findAll()
            }
        )
    }

    handleOnPagePerPageChange = (p) => {
        this.setState(
            (state) => ({
                ...state,
                itemPerpage: p,
                page: 1,
                loading: true,
                pageSizeOptions: {
                    ...state.pageSizeOptions,
                    sizePerPage: p,
                    page: 1,
                }
            }),
            () => {
                this.setState(
                    (state) => ({
                        ...state,
                        loading: true
                    }), () => {
                        this.findAll()
                    }
                )
            }
        )
    }

    handleEditCell = (e, itemId, columnId) => {
        e.preventDefault()
        console.log("handleEditCell", itemId, columnId)
        
        this.setState((state) => ({
            ...state,
            items: state.items.map(item => {
                return {
                    ...item,
                    enableEdit: item.id==itemId
                }
            }),
            fields: state.fields.map(field => {
                return {
                    ...field,
                    enableEdit: field.columnId==columnId
                }
            })
        }))
    }

    handleCellEdited = (newValue, itemId, columnId, columnType="", saveNewValue=true) => {
        this.setState((state) => ({
                ...state,
                items: state.items.map(item => {
                    if(item.id==itemId){
                        if(saveNewValue){
                            const oldValue = item[columnId]
                            if(oldValue!=newValue){
                                let newItem = {...item}

                                newItem[columnId] = newValue

                                delete newItem.enableEdit

                                state.fields.filter(field=> field.type=="object").forEach(field=>{
                                    if (newItem[field.columnId]) {
                                        newItem[field.columnId] = newItem[field.columnId].id
                                    }
                                })

                                ProductRepository.editWithoutValidation({ 
                                    ...newItem
                                }).then(data => {
                                    this.findAll()
                                }).catch(response => {
                                    this.catchError(response, ()=>{
                                        // Rollback to old value
                                        this.setState((state) => ({
                                            ...state,
                                            items: state.items.map(i=>{
                                                if(i.id==itemId){
                                                    i[columnId] = oldValue
                                                }
                                                return i
                                            }),
                                            loading: false,
                                            dialog: {...state.dialog, show: false},
                                            seed:  state.seed + 1
                                        }))
                                    })
                                })

                                item[columnId]= newValue
                                return {
                                    ...item,
                                    enableEdit: false
                                }
                            }
                        }
                        return {
                            ...item,
                            enableEdit: false
                        }
                    }
                    return item
                }),
                fields: state.fields.map(field => {
                    return {
                        ...field,
                        enableEdit: false
                    }
                }),
                seed:  state.seed + 1
            }), () => {
        })
    }

    handleBatchCellEdited = (newValue, columnId, columnType="") => {
        this.setState((state)=>({ ...state, loading: true}))

        let updatedItems = this.state.items.map((item)=>{
            if(this.state.selectedProductsIds.indexOf(item.id) >= 0){
                let newItem = {...item}
                newItem[columnId] = newValue
                return newItem
            }
            return item
        })

        let objectFields = this.state.fields.filter(field=> field.type=="object")

        let parsedItems = updatedItems.map((p)=>{
            let newItem = {...p}
            objectFields.forEach(field=>{
                if (newItem[field.columnId]) {
                    newItem[field.columnId] = newItem[field.columnId].id
                }
            })
            delete newItem["enableEdit"]
            return newItem
        })

        ProductRepository.editAll(parsedItems).then(()=>{
            this.setState((state)=>({...state, items:updatedItems}))
            this.findAll()
        }).catch(response => {
            this.catchError(response)
        }).finally(()=>{
            this.setState((state)=>({ ...state, loading: false, seed:  state.seed + 1}),()=>{
                document.getElementById("product_list_table").scrollLeft=0
            })

        })
    }

    unSelectAllProducts = ()=>{
        this.setState((state)=>({...this.state, selectedProductsIds: [], seed:  state.seed + 1}))
    }

    selectAllProducts (event){
        //let {checked} = event.target
        let checked = this.state.selectedProductsIds.length==this.state.items.length

        let selectedProducts = []

        if(!checked){
            selectedProducts = this.state.items.map((e)=>{return e.id})
        }
        this.setState((state)=>({...this.state, selectedProductsIds: selectedProducts}))
    }

    selectProduct(id, event){
        let checked = (this.state.selectedProductsIds.indexOf(id) ==-1)
        let selectedProducts = this.state.selectedProductsIds

        if(checked){
            selectedProducts.push(id);
        }else{
            selectedProducts = selectedProducts.filter((i)=>{return i!=id})
        }
        this.setState((state)=>({...state, selectedProductsIds: selectedProducts}))
    }

    validateSelectedProducts(event){
        event.preventDefault()
        let selectedProducts = this.state.items.filter((p)=>{return this.state.selectedProductsIds.indexOf(p.id)>=0 })
        let objectFields = this.state.fields.filter(field=> field.type=="object")
        let dateFields = this.state.fields.filter(field=> field.type=="date")
        let parsedItems = selectedProducts.map((p)=>{
            let newItem = {...p}
            objectFields.forEach(field=>{
                if (newItem[field.columnId]) {
                    newItem[field.columnId] = newItem[field.columnId].id
                }
            })
            dateFields.forEach(field=>{
                if (newItem[field.columnId]) {
                    newItem[field.columnId] = moment(newItem[field.columnId]).format("YYYY-MM-DD")
                }
            })
            return newItem
        })

        ProductRepository.validateProducts(parsedItems).then((response)=>{
            if(Object.keys(response).includes("success")){
                let dialog = {
                    ...this.state.dialog,
                    show: true,
                    handleConfirm: this.closeDialogModal,
                    handleClose: this.closeDialogModal,
                    type: DIALOG_TYPE.SUCCESS,
                    message: response.success,
                    title: this.props.t("OPERATION_SUCCESSED")
                }

                this.setState((state) => ({
                    ...state,
                    dialog: dialog
                }))
            }else{
                this.setState((state)=>(
                    {
                        ...state,
                        validationErrors: response.errors
                    }
                ))
            }
        }).catch(response => {
            catchError(response)
        })
    }

    clearValidationErrors(productNum){
        let errors = this.state.validationErrors
        delete errors[productNum]

        this.setState((state)=>(
            {
                ...this.state,
                validationErrors : errors
            }
        ))
    }

    hideImportPage (){
        this.setState((state)=>(
            {
                ...this.state,
                showImportPage : false
            }
        ), ()=>{
            this.findAll()
        })
        
    }
    showImportPage (e){
        e.preventDefault()
        this.setState((state)=>(
            {
                ...this.state,
                showImportPage : true
            }
        ))
    }

    exportProjectPics(projectId, projectName){
        this.setState(
            (state) => ({ ...state, loading: true}),
            () => {
                ProjectsRepository.exportProjectPics(projectId, AuthorizationChecker.isGranted(["VENDOR_READ_PROJECT"]) ).then((result)=>{


                    var xhr = new XMLHttpRequest(), a = document.createElement('a'), file;

                    xhr.open('GET', ProjectsRepository.oneTimeDownloadLink(result.data), true);
                    xhr.responseType = 'blob';
                    xhr.onload =  () => {
                        file = new Blob([xhr.response], { type : 'application/octet-stream' });
                        a.href = window.URL.createObjectURL(file);
                        a.download = projectName + "_pics_"+(new Date()).toISOString() + ".zip";
                        a.click();
                        this.setState((state) =>({...this.state, loading: false}))
                    };
                    xhr.send();
                    

                }).catch(response => {
                    this.catchError(response);
                });
            }
        );
    }

    exportProject(){
        this.setState(
            (state) => ({ ...state, loading: true}),
            () => {
                ProjectsRepository.exportProject([this.props.projectId]).then((result)=>{
                    var blob=new Blob([result.data]);
                    var link=document.createElement('a');
                    link.href=window.URL.createObjectURL(blob);
                    link.download="project_"+(this.state.project ? this.state.project.name : "")+"_exported_"+(new Date()).toISOString() + ".csv";
                    link.click();

                    this.findAll()
                }).catch(response => {
                    this.catchError(response);
                });
            }
        );
    }

    getparentId = (field, item) => {
        if(this.niveau.indexOf(field)>=0){
            //get the index of the field
            let index = this.niveau.indexOf(field)
            //get the parent field
            let parentField = this.niveau[index-1]
            //get the parent id
            return item[parentField]
        }
    }

    render() {
    
        return <>
            <LoadingContainer loading={this.state.loading} style={{marginTop:"100px"}}>
                <Card>
                    <CardBody>
                        <div className="mb-4">
                            <Row>
                                <Col xs="12">
                                    <div className="page-title-box justify-content-between">
                                        <div className="">
                                            <ol className="breadcrumb m-0">
                                                <BreadcrumbItem>
                                                    <Link style={{marginLeft: "15px"}} className="btn" to={"/pages-projects"}> <i className='bx bx-chevron-left'></i> {this.props.t("PROJECTS")}</Link>
                                                </BreadcrumbItem>
                                                <BreadcrumbItem className="d-flex align-items-center">
                                                    <Link to="#"> {this.props.t("PRODUCTS")} </Link>                                                    
                                                </BreadcrumbItem>
                                            </ol>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col md="8">
                                    { 
                                        this.props.showAdd && AuthorizationChecker.isGranted(["VENDOR_READ_PROJECT"]) &&
                                        (
                                            <Link className="btn btn-primary me-3" to={this.props.addUrl}> {this.props.t("ADD_PRODUCT")} </Link>
                                        )
                                    }

                                    {
                                        this.state.selectedProductsIds.length>0 && (
                                            <Link className="btn btn-primary me-3" to={"#"} onClick={(e)=>{this.validateSelectedProducts(e)}}> {this.props.t("VALIDATE_SELECTED_PRODUCTS")} </Link>
                                        )
                                    }

                                    {
                                        this.props.showAdd && AuthorizationChecker.isGranted(["VENDOR_EDIT_PROJECT"]) && 
                                        (
                                            <Link className="btn btn-primary me-3" to={"#"} onClick={(e)=>{this.showImportPage(e)}}> {this.props.t("IMPORT_PRODUCTS")} </Link>
                                        )
                                    }

                                   
                                        <button className="btn btn-primary me-3" disabled={this.state.project==null || this.state.items.length==0} onClick={()=>{this.exportProjectPics(this.props.projectId, this.state.project ? this.state.project.name : "")}}>{(this.props.t("EXPORT_PROJECT_PICS"))} </button> 
                                   
                                    { ( AuthorizationChecker.isGranted(["BUYER_READ_PROJECT"]) || AuthorizationChecker.isGranted(["MD_READ_PROJECT"]) || AuthorizationChecker.isGranted(["ECOM_READ_PROJECT"]) ) &&  <button className="btn btn-primary" disabled={this.state.project==null || this.state.items.length==0} onClick={()=>{this.exportProject()}}>{(this.props.t("EXPORT_PROJECT"))} </button>}

                                </Col>
                                <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block float-end">
                                        <form className="search-box d-inline-block">
                                            <div className="position-relative">
                                                <label htmlFor="search-bar" className="search-label">
                                                    <span id="search-bar-label" className="sr-only"> {this.props.t("FIND_IN_TABLE")}</span>
                                                    <input
                                                        id="search-bar"
                                                        type="text"
                                                        name="query"
                                                        className="form-control "
                                                        placeholder={this.props.t("SEARCH")}
                                                        onChange={(e) => this.handleFormInputChange(e)}
                                                        value={this.state.query}/>
                                                </label>
                                                <i className="bx bx-search-alt search-icon"/>
                                            </div>
                                        </form>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        {
                            this.state.validationErrors && Object.keys(this.state.validationErrors).map( (productNum, ind)=>
                                <div className="row error-box" key={ind}>
                                    <div className="error-box-title">
                                        <p> <strong> {this.props.t("VALIDATION_ERRROS_ON_PRODUCT")} : {productNum}</strong></p>
                                        <button type="button" className="btn-close" aria-label="Close" onClick={()=>{this.clearValidationErrors(productNum)}}></button>
                                    </div>
                                    <ul>
                                        {this.state.validationErrors[productNum].split(";").map((error,i)=>{
                                            return (
                                                <li key={i}> 
                                                    <code>{ error.split(":")[0].trim() + " : " + error.split(":")[1]}</code>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            )
                        }

                        <PaginationWrap
                            page={this.state.page}
                            paginationProps={this.state.pageSizeOptions}
                            handleOnPagePerPageChange={(p) => this.handleOnPagePerPageChange(p)}
                            itemPerpage={this.state.itemPerpage}
                            onPageChange={(p) => this.onPageChange(p)}
                            totalItems={this.state.total}
                        />
                        <LoadingContainer loading={this.state.loadingSearch}>
                            <Row className="my-3">
                                <Col xl="12">
                                    <div className="table-responsive mt-3" id="product_list_table">
                                        <div className="react-bootstrap-table">
                                            <table className="table align-middle table-nowrap job-table">
                                                <thead className="thead-light">
                                                <tr>
                                                    <th>
                                                        <Input type="checkbox" onClick={(e)=>{this.selectAllProducts(e)}} onChange={()=>{}} checked={this.state.selectedProductsIds.length==this.state.items.length}/>
                                                    </th>

                                                    <th tabIndex={0}/>

                                                    {   
                                                        this.state.fields.length > 0 && this.state.fields.filter(i => i.show).map((field, ind)=>{ return <th key={field.columnId}  className={((ind + 1) % 2) === 0 ? "odd" : ""}> {this.props.t(field.name)}</th> })
                                                    }
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    this.state.selectedProductsIds.length>0 && (
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            {
                                                                //@TODO : handle the case where the field is child of another field 
                                                                this.state.fields.map((field,index)=>
                                                                    <td key={index}>
                                                                        <CellEditor
                                                                            type={field.type}
                                                                            chooseAttr={field.chooseAttr}
                                                                            field={field.columnId}
                                                                            disabled={field.isDisabled==1}
                                                                            //parentId = {this.getparentId(field.columnId, item)}
                                                                            handleCloseUpdate={(e, newValue) => this.handleBatchCellEdited(newValue, field.columnId, field.type)}
                                                                            handleCloseWithoutSave={(e, newValue) => {this.unSelectAllProducts()}}
                                                                            onUpdated={(newValue) => {}}
                                                                        />
                                                                    </td>

                                                                )
                                                            }
                                                        </tr>
                                                    )
                                                }
                                                {this.state.items.length > 0 && this.state.items.map((item, index) =>
                                                    <tr key={index} className={this.state.selectedProductsIds.indexOf(item.id) >=0 ? "tab-bgcolor" : ""}>
                                                        <td className={this.state.selectedProductsIds.indexOf(item.id) >=0 ? "tab-bgcolor" : ""}>
                                                            <Input type="checkbox" onClick={(e)=>{this.selectProduct(item.id,e)}} onChange={()=>{}}  checked={this.state.selectedProductsIds.indexOf(item.id) >=0}/>
                                                        </td>

                                                        <td className={this.state.selectedProductsIds.indexOf(item.id) >=0 ? "tab-bgcolor" : ""}>
                                                            <ActionsFormatter
                                                                item={item}
                                                                onDelete={(event, elt) => this.handleDelete(event, elt)}
                                                                path={this.props.path}
                                                                enableEdit ={this.props.showEdit} enableView ={this.props.showView} 
                                                                enableRemove ={
                                                                    AuthorizationChecker.isGranted(["VENDOR_EDIT_PROJECT"]) ? (this.state.CORRECT_STATUS.indexOf(this.state.project.statusId) >=0) :
                                                                    AuthorizationChecker.isGranted(["BUYER_EDIT_PROJECT"]) ?  ( (this.state.CORRECT_STATUS.indexOf(this.state.project.statusId) >=0) && this.state.project.purchaserId==UserProvier.get().id) :
                                                                    AuthorizationChecker.isGranted(["MD_EDIT_PROJECT"]) ?  ( (this.state.CORRECT_STATUS.indexOf(this.state.project.statusId) >=0) && this.state.project.dmId==UserProvier.get().id) : true
                                                                }
                                                            />
                                                        </td>

                                                        {   
                                                            this.state.fields.filter(i => i.show).map((field,ind)=>{ 
                                                            return <td key={ind} className={`${((ind + 1) % 2) === 0 ? "odd" : ""} ${this.state.selectedProductsIds.indexOf(item.id) >=0 ? "tab-bgcolor" : ""}`}>
                                                                 {
                                                                    item.enableEdit && field.enableEdit ?
                                                                    <Fragment>
                                                                        <CellEditor
                                                                            type={field.type}
                                                                            chooseAttr={field.chooseAttr}
                                                                            field={field.columnId}
                                                                            value={field.type=="checkbox" ? item[field.columnId]==1 : item[field.columnId]}
                                                                            disabled={field.isDisabled==1}
                                                                            parentId = {this.getparentId(field.columnId, item)}
                                                                            handleCloseUpdate={(e, newValue) => this.handleCellEdited(newValue, item.id, field.columnId, field.type)}
                                                                            handleCloseWithoutSave={(e, newValue) => this.handleCellEdited(newValue, item.id, field.columnId, field.type, false)}
                                                                            onUpdated={(newValue) => {}}
                                                                        />
                                                                    </Fragment>
                                                                    :
                                                                    <Fragment>
                                                                        <CellValue
                                                                            columnId={field.columnId}
                                                                            brand={ this.state.companyId==1 ? (item.brandId ? item.brandId.description : "" ) : item.productBrand }
                                                                            description = { this.state.companyId==1 ? item.briefDescription : item.description }
                                                                            key={field.name+this.state.seed}
                                                                            type={field.type}
                                                                            style={""}
                                                                            disabled={false}
                                                                            chooseAttr={field.chooseAttr}
                                                                            value={field.type=="checkbox" ? item[field.columnId]==1 : item[field.columnId]}
                                                                            onClick={(event => this.handleEditCell(event, item.id, field.columnId, field.type))}
                                                                        />
                                                                    </Fragment>
                                                                 }
                                                                 
                                                            </td>
                                                        }) 
                                                        }
                                                    </tr>)}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </LoadingContainer>
                        <PaginationWrap
                            page={this.state.page}
                            paginationProps={this.state.pageSizeOptions}
                            handleOnPagePerPageChange={(p) => this.handleOnPagePerPageChange(p)}
                            itemPerpage={this.state.itemPerpage}
                            onPageChange={(p) => this.onPageChange(p)}
                            totalItems={this.state.total}
                        />
                    </CardBody>
                </Card>
            </LoadingContainer>
            {this.state.showImportPage &&
            <ImportProductModalPage
                projectId={this.props.projectId}
                handleCloseModal={() => this.hideImportPage()}
                onProductsImported={() => {console.log("Products imported")}}
                showModal={this.state.showImportPage}
            />}
            <DialogBoxComponent {...this.state.dialog}/>
        </>
    }
}

export default withTranslation()(PagesProducts)