/* eslint-disable react/prop-types */
/* eslint-disable react/no-is-mounted */
import React, { Fragment, useEffect, useState } from "react"
import {useParams} from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    BreadcrumbItem
} from "reactstrap"
import {DIALOG_TYPE, HTTP_RESPONSE} from "../Utility/Helpers/constants";
import "../Utility/Helpers/style.css";
import UsersRepository from "pages/Users/repository/users-repository";
import ActionsFormatterSupplier from "pages/Utility/Helpers/ActionsFormatterSupplier";
import { withTranslation } from "react-i18next";

class PagesSupplier extends React.Component {

    /**
     *
     * @param props
     */
     constructor(props) {
        super(props);

        // eslint-disable-next-line react/prop-types
        this.state = {
            projectStatus : {},
            items: [],
            userList: [],
            errors: [],
            total: 0,
            entity: null, // element courant
            itemPerpage: 10,
            loading: true,
            loadingSearch: false,
            query: "",
            showConfirmDialog: false,
            pageSizeOptions: {
                totalSize: 0, // replace later with size(customers),
                custom: true,
                onPageChange: (page) => this.onPageChange(page),
                onSearchChange: this.handleSearchChange,
                page: 1,
                sizePerPage: 10,
                pageStartIndex: 1,
                sizePerPageList: 10,
                showTotal: true,
                onSizePerPageChange: {}
            },
            dialog: {
                show: false,
                onConfirm: () => {
                },
                handleClose: () => {
                },
                message: "",
                type: DIALOG_TYPE.ALERT,
            },
            selectedProjectsIds: [],
            unSelectableProjectsIds: [],
            
        }
      //  this.onPageChange = this.onPageChange.bind(this);
       // this.handleSearchChange = this.handleSearchChange.bind(this);
        
       const items = JSON.parse(localStorage.getItem('authUser'));
       if (items) {
       // const ROLE_ID = (items.companyId == 1) ? VENDOR_ROLE_MONDOU_ID : VENDOR_ROLE_RENS_ID;
       const ROLE_ID = (items.companyId == 1) ? "MONDOU" : "RENS";
        UsersRepository.findByRoleCie(ROLE_ID,items.companyId).then(data=>{
            this.setState((state)=>({...state, userList: data}))
        })
       }
   
    
        
      
    }
    componentDidMount() {
    }
    render() {
    return (

        <>
        <div className="page-content">
            <title>{this.props.t("SUPPLIERS")}</title>
        <Container fluid={true}>
            <Card>
                <CardBody>
                    <div className="mb-4">
                        <Row>
                            <Col xs="12">
                                <div className="page-title-box justify-content-between">
                                <div className="">
                                    <ol className="breadcrumb m-0">
                                    <BreadcrumbItem>.</BreadcrumbItem>
                                    <BreadcrumbItem>
                                        {this.props.t("SUPPLIERS")}
                                    </BreadcrumbItem>
                                    </ol>
                                </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                  {
                    this.props.showAdd && (
                      <Col md="8">
                        <this.props.Link className="btn btn-primary" to={this.props.addUrl}>{this.props.t("ADD_USER")}</this.props.Link>
                      </Col>
                    )
                  }
                  
                </Row>
                <Row>
                    <Col xl="12">
                        <div className="table-responsive">
                            <div className="react-bootstrap-table">
                                <table className="table table align-middle table-nowrap">
                                    <thead className="thead-light">
                                    <tr>
                                        <th tabIndex={0}>{this.props.t("NAME")}</th>
                                        <th tabIndex={0}>{this.props.t("FIRST_NAME")}</th>
                                        <th tabIndex={0}>{this.props.t("COMPANY_NAME")}</th>
                                        <th tabIndex={0}>{this.props.t("INITIALS")}</th>
                                        <th tabIndex={0}>{this.props.t("EMAIL")}</th>
                                        <th tabIndex={0}>{this.props.t("USERNAME")}</th>
                                        <th tabIndex={0}>{this.props.t("SUPPLIERS")}</th>
                                        <th tabIndex={0}>{this.props.t("STATUS")}</th>
                                        <th tabIndex={0}/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.userList.map((item, index) => <tr key={index}>
                                        <td>{item.lastname}</td>
                                        <td>{item.firstname}</td>
                                        <td>{item.companyName ? item.companyName : ""}</td>
                                        <td>{item.initials}</td>
                                        <td>{item.email}</td>
                                        <td>{item.username}</td>
                                        <td>
                                            {item.suppliers.map((sup,indexsup) => 
                                                <Fragment key={indexsup}><span className="badge bg-info p-2">{sup.nom}</span><br></br></Fragment>
                                            )}

                                        </td>
                                        <td>{item.enabled == 1 ? <span className="badge bg-primary p-2">{this.props.t("ACTIVE")}</span> :
                                        <span className="badge bg-danger p-2">{this.props.t("INACTIVE")}</span>}
                                        </td>

                                        
                                        <td>
                                        <ActionsFormatterSupplier
                                        user={item}
                                        
                                        
                                        showEdit = {this.props.showEdit} showView = {false} showDelete = {false}
                                    />
                                        </td>
                                    </tr>)}
                                    </tbody>
                                </table>
                            </div>
                            
                        </div>
                    </Col>
                </Row>
                    </div>
                </CardBody>
            </Card>
        </Container>
        </div>
    </>
    )
    }
}

export default withTranslation()(PagesSupplier); 