import axios from "axios";
import {HTTP_RESPONSE} from "../../Utility/Helpers/constants";
import UserProvier from "../../../security/UserProvier";
import AuthorizationChecker from "../../../security/AuthorizationChecker";

UserProvier.setToken();

export const DATA_API_URL = process.env.REACT_APP_API_BASE_URL + 'references';
export const queriesAll = (itemPerPage = 15, page = 1, search = '', cieId = 1) => {
    return (
        DATA_API_URL +'?filter[where][or][0][name][like]=%'+search+'%' +'&filter=[where][name][options]=i' +
      '&filter[where][or][1][code][like]=%'+search+'%' +'&filter=[where][code][options]=i' +
      '&filter[where][or][1][description][like]=%'+search+'%' +'&filter=[where][description][options]=i' +
      '&filter[where][companyId]=' + cieId +
      '&filter[order]=id ASC'
    )
  }

const ReferenceRepository = { 

    getAll: async (cieId = 1) => {
        try {
            const response = await axios.get(queriesAll(cieId));
            return response.data;
        } catch (e) {
            if (e.response) {
                throw e.response;
            } else {
                throw { status: HTTP_RESPONSE.HTTP_NOT_FOUND };
            }
        }
    },

    uploadAttachment: async (item) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        const formData = new FormData();
        // Update the formData object
        formData.append(
            "references",
            item,
            item.name,
        );
        try {
            const response = await axios
                .post(DATA_API_URL + '/import', formData, config);
                console.log(response);
            return response.data;
        } catch (e) {
            if (e.response) {
                throw e.response;
            } else {
                throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND };
            }
        }
    }

}

export default ReferenceRepository;