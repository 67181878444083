/* eslint react/prop-types: 0 */
import React, { useEffect, useState, useMemo } from "react"

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import ExportBtn from "./components/export-btn";
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
const { ExportCSVButton } = CSVExport;
const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button className="btn btn-success" onClick={ handleClick }>CSV</button>
      </div>
    );
  };
const TimelineTable = (props) => {
    

    const { logs,name } = props;
    const [data, setData] = useState([]);
    const [project_name, setProjectName] = useState("");
    const columns = [
        {
            dataField: 'date',
            text: 'Date'
        },
        {
            dataField: 'action',
            text: 'Action'
        },
        {
            dataField: 'user',
            text: props.t("_User_")
        },
        {
            dataField: 'type',
            text:props.t("_LOG_TYPE_")
        },
        {
            dataField: props.t("_MORE_INFO_"),
            text: 'More Info'
        }
    ];

    useEffect(() => {
        const processLogs = () => {
            if (logs) {
                //map the logs to the table columns
                const mappedData = logs.map(log => {
                    return {
                        //display the date in a readable format
                        id : log.id,
                        date: log.createdAt.split("T")[0] + " " + log.createdAt.split("T")[1].split(".")[0],
                        action: props.t(log.projectLogsType.key),
                        user: log.user.firstname + " " + log.user.lastname,
                        type: log.type,
                        info: log.info
                    }
                });
                setProjectName(name);
                setData(mappedData);
            }
        };
    
        processLogs();
    }, [logs, name, props.t]);

    return (
        <ToolkitProvider
            keyField="id"
            data={data}
            columns={columns}
            exportCSV={ {
                fileName: project_name + '_logs.csv',
              } }
        >
            {
                props => (
                    <div>
                        <ExportBtn  {...props.csvProps}>Export CSV</ExportBtn >
                        <BootstrapTable
                            {...props.baseProps}
                        />
                    </div>
                )
            }
        </ToolkitProvider>
    );
}
TimelineTable.propTypes = {
    logs: PropTypes.array,
    t: PropTypes.func
}
export default withTranslation()(TimelineTable);