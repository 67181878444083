import React from "react";

import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import CarouselPage from "../Authentication/CarouselPage";
import { PARAMETERS_API_URL, USERS_API_URL_LG_GUEST, USERS_COMPANY_API_URL, COMPANY_API_URL } from "../../helpers/api-urls";
import { RegisterComponent } from "ppm-react-authentication";
import { CopyrightComponent } from "ppm-skin";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import LanguageDropdown from "../../components/CommonForBoth/TopbarDropdown/LanguageDropdown";

const RegisterPage = (props) => {
    const navigate = useNavigate();

    const handleSuccessLogin = (u) => {
        console.log("r user:", u);
        if (u.langue) {
            i18n.changeLanguage(u.langue || "en");
            localStorage.setItem("I18N_LANGUAGE", u.langue || "en");
        }

        setTimeout(() => {
            navigate('/auth-email-verification');
        }, 2000);
    };

    return (
        <React.Fragment>
            <div>
                    <title>New account </title>
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <CarouselPage />
                        <Col xl={4}>
                            <div className="auth-full-page-content p-md-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="mb-1">
                                            <LanguageDropdown />
                                        </div>
                                        {/** By default use initials field */}
                                        <RegisterComponent
                                            apiUrl={USERS_API_URL_LG_GUEST}
                                            apiCompanyUrl={COMPANY_API_URL}
                                            apiUserCompanyUrl={USERS_COMPANY_API_URL}
                                            onRegisterSuccess={(u) => { handleSuccessLogin(u) }}
                                            useInitials={false}
                                            useLangue={true}
                                            useCompanyName={true}
                                        />
                                        <div className="text-center">
                                            <p>
                                                {props.t("ALREADY_HAVE_ACCOUNT")} {" "}
                                                <Link to="/login"
                                                    className="font-weight-medium text-primary">
                                                    {" "}
                                                    {props.t("CONNECT")}
                                                </Link>{" "}
                                            </p>
                                        </div>
                                        <div className="text-center">
                                            <CopyrightComponent
                                                apiUrl={PARAMETERS_API_URL}
                                                code={"DEFAULT"} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(RegisterPage);

RegisterPage.propTypes = {};
