import axios from "axios";
import {HTTP_RESPONSE} from "../../Utility/Helpers/constants";
import UserProvier from "../../../security/UserProvier";
import {USERS_COMPANY_ROLES_API_URL, USERS_COMPANY_API_URL} from "../../../helpers/api-urls";
UserProvier.setToken();

export const DATA_API_URL = process.env.REACT_APP_API_BASE_URL + 'userlg';
export const USERS_API_URL = process.env.REACT_APP_API_BASE_URL + 'users';
export const DATA_API_URL_UC = process.env.REACT_APP_API_BASE_URL +'user-companies'
export const DATA_API_URL_UCS = process.env.REACT_APP_API_BASE_URL +'user-company-suppliers'
export const DATA_API_URL_UCR = process.env.REACT_APP_API_BASE_URL +'user-company-roles'
export const VENDOR_ROLE_RENS_ID= process.env.REACT_APP_VENDOR_ROLE_RENS_ID;
export const VENDOR_ROLE_MONDOU_ID = process.env.REACT_APP_VENDOR_ROLE_MONDOU_ID;

const queriesAllUCR = () => {
    return (
        DATA_API_URL_UCR +'?filter[include][]=userCompany'+
      '&filter[include][]=role'
    )
  }

  const queriesAllUC = (cieId) => {
    return (
        USERS_COMPANY_API_URL +'?filter[where][companyId]='+ cieId + '&filter[include][]=user&filter[include][]=company'
    )
  }


const UserCompanyRepository = {

    findAllUCR : async () => {
        try {
            const response = await axios.get(queriesAllUCR());
            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error(HTTP_RESPONSE.INTERNAL_SERVER_ERROR);
        }
    },
    findAllUC : async (cieId) => {
        try {
            const response = await axios.get(queriesAllUC(cieId));
            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error(HTTP_RESPONSE.INTERNAL_SERVER_ERROR);
        }
    },
}

export default UserCompanyRepository;