import React from 'react'
import {Link, useLocation} from "react-router-dom";
import AuthorizationChecker from "../../../security/AuthorizationChecker";

const ActionsFormatter = (props) => {
     // eslint-disable-next-line react/prop-types
    const {item,onDelete,path = "",showEdit = true,showView = true,showDelete = true,location = useLocation()} = props;

    return (
        <div className="bx-pull-right file-system">
            {showView && (
                <Link className="btn" to={ (location.pathname ? location.pathname :location ) + '/' + item.id + '/detail'}>
                    <i className="bx bxs-show action-icon"/>
                </Link>
            )}
            {showEdit && (
                <Link className="btn" to={ (location.pathname ? location.pathname :location ) + '/' + item.id + '/edit'}>
                    <i className="bx bxs-edit action-icon"/>
                </Link>
            )}
            {showDelete && (
                <Link
                    style={{marginLeft: "15px"}}
                    className="btn"
                    to="#"
                    onClick={() => onDelete(item)}
                >
                    <i className="bx bx-trash action-icon"/>
                </Link>
            )}
        </div>
    )
}



export default ActionsFormatter