import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import { Table, Row, Col, Button, Input, CardBody, InputGroup } from "reactstrap"
import { Filter, DefaultColumnFilter } from "./filters"
import { withTranslation } from "react-i18next"
import styled from 'styled-components'
import CellValue from "components/Common/Grid/Widget/CellValue";
import CellEditor from "components/Common/Grid/Widget/CellEditor";
// Define a default UI for filtering



// 

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
}) {
  const [user, setuser] = useState(JSON.parse(localStorage.getItem("authUser")));//retrieving current user information
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)
//console.log('USER****',user);
  return (
    <React.Fragment>
      <Col md={4}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value)
                  onChange(e.target.value)
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`${count} records...`}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  customPageSize,
  className,
  tableClass,
  customPageSizeOptions,
  t,
  hiddenColumnsArr = [],
  updateData, skipPageReset,cieId
}) => {

  const initstate = {
    hiddenColumns: hiddenColumnsArr.map(column => column),
    pageIndex: 0,
    pageSize: customPageSize,
  }
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter, Cell: EditableCell},
      initialState: initstate,
      autoResetPage: !skipPageReset,
      useGlobalFilter,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateData,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )
  
  function EditableCell({
    value: initialValue,
    row: { index },
    column: { id },
    updateData, // This is a custom function that we supplied to our table instance
  }) {
    const [value, setValue] = React.useState(initialValue);
    const onChange = (e) => {
      //console.log(e.value)
      setValue(e.value);
    };
    const onBlur = () => {
      updateData(index, id, value);
    };

    const handleCloseEdit = (newValue) => {
      setValue(newValue);
    };

    const handleEditCellValue = (newValue, index) => {
      updateData(index, id, newValue);
    };
    

    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    if (id =="configValue")
    {
      //return <input value={value} onChange={onChange} onBlur={onBlur} />;
      return (
      <Fragment>
        <CellEditor
           type="text"
           field="configValue"
           disabled={false}
           //parentId = {this.getparentId(field.columnId, item)}
           value={value}
           handleCloseUpdate={(e, newValue) => handleEditCellValue(newValue, index)}
          // handleCloseUpdate={(e) => {console.log("e", e);}}
           handleCloseWithoutSave={(e, newValue) => handleCloseEdit(newValue)}
           onUpdated={(e) => {onChange(e)}}/>
       </Fragment>)
    }
    else
    {
   return (
    <Fragment>
        <CellValue
            type="text"
            field={id}
            disabled={false}
            //parentId = {this.getparentId(field.columnId, item)}
            value={value}
            />
        </Fragment>
        );
    }
  }

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }
  const [user, setuser] = useState(JSON.parse(localStorage.getItem("authUser")))
  return (
    <Fragment>
      <Row className="mb-2">
        <Col md={customPageSizeOptions ? 2 : 1}>
          <select
            className="form-select"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[5, 10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {t("_SHOW_")} {pageSize}
              </option>
            ))}
          </select>
        </Col>
        
          
      </Row>
      <div className="table-responsive react-table tableContainer">
        <Table bordered hover {...getTableProps()} className={tableClass}>
        <thead className="table-light table-nowrap">
            {headerGroups.map((headerGroup, index) => (
              <tr key={index} {...delete headerGroup.getHeaderGroupProps()['key']}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>
                    <div className="mb-2" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    <Filter column={column} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>


          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map((cell,index) => {
                      return (
                        <td key={index} {...delete cell.getCellProps()['key']}>
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
        
      </div>
      <Row className="justify-content-between align-items-center flex-nowrap">
        <Col className="col-auto">
        </Col>
        <Col>
          <Row className="justify-content-md-end justify-content-center align-items-center flex-nowrap">
            <Col className="col-auto">
              <div className="d-flex gap-1">
                <Button
                  color="primary"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  {"<<"}
                </Button>
                <Button
                  color="primary"
                  onClick={previousPage}
                  disabled={!canPreviousPage}
                >
                  {"<"}
                </Button>
              </div>
            </Col>
            <Col className="col-auto d-none d-md-block">
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </Col>
            <Col className="col-auto">
              <Input
                type="number"
                min={1}
                style={{ width: 70 }}
                max={pageOptions.length}
                defaultValue={pageIndex + 1}
                onChange={onChangeInInput}
              />
            </Col>
            <Col className="col-auto">
              <div className="d-flex gap-1">
                <Button
                  color="primary"
                  onClick={nextPage}
                  disabled={!canNextPage}
                >
                  {">"}
                </Button>
                <Button
                  color="primary"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  globalFilter: PropTypes.any,
  setGlobalFilter: PropTypes.any,
  t: PropTypes.func
}

export default withTranslation()(TableContainer)
