// @ts-ignore
import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";;
//import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";
import store from "./store";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render  (
  <Provider store={store}>
  <React.Fragment>
    <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <App />
      </I18nextProvider>
    </BrowserRouter>
  </React.Fragment>
</Provider>

);
//const container = document.getElementById('root');
//const root = ReactDOM.createRoot(document.getElementById("root"));
// @ts-ignore
//root.render(app);

//serviceWorker.unregister();
