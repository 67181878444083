import React from "react";

import {ReferenceLabelListComponent} from "ppm-react-references";
import {REFERENCE_LABELS_API_URL} from "../../helpers/api-urls";
import { Link } from "react-router-dom";
import AuthorizationChecker from "security/AuthorizationChecker";
import { withTranslation } from "react-i18next";

class PagesReferenceLabels extends React.Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="page-content">
                    <title>{this.props.t("REFERENCE_LABEL")}</title>
                    
                <ReferenceLabelListComponent 
                    apiUrl={REFERENCE_LABELS_API_URL}
                    addUrl={"/pages-references/"+this.props.referenceId+"/"+this.props.referenceValueId+"/add"}
                    path={"/pages-references/"+this.props.referenceId+"/"+this.props.referenceValueId}
                    referenceValueId={this.props.referenceValueId}
                    basePath={"/pages-references/"+this.props.referenceId+"/"+this.props.referenceValueId+"/"}
                    referenceId={this.props.referenceId}
                    backPath={"/pages-references/"+this.props.referenceId+"/"}
                    Link={Link}
                    showEdit = {AuthorizationChecker.isGranted(["EDIT_REFERENCE_LABELS"])} 
                    showView = {AuthorizationChecker.isGranted(["READ_REFERENCE_LABELS"])} 
                    showDelete = {false} 
                    showAdd = {AuthorizationChecker.isGranted(["ADD_REFERENCE_LABELS"])} 
                />
            </div>
        )
    }
}

export default withTranslation()(PagesReferenceLabels)
