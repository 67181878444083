import React, { useEffect,useMemo ,useState} from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import { ReferenceValueListComponent } from "ppm-react-references";
import { REFERENCE_VALUES_API_URL } from "../../helpers/api-urls";
import AuthorizationChecker from "security/AuthorizationChecker";
import ReferenceValueRepository from "./Repository/reference_value_repository";
import { createColumnHelper } from '@tanstack/react-table';
import TableContainer from "../Utility/Helpers/TableContainer";
import LoadingContainer from "../Utility/Helpers/LoadingContainer";
import {Row, Col, Card, CardBody, BreadcrumbItem} from "reactstrap"
const PagesReferenceValues = (props) => {
    const { t } = useTranslation();
    const { referenceId } = useParams();
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    const [items, setItems] = useState([]);
    const columnHelper = createColumnHelper();
    const [isLoading, setIsLoading] = useState(false);

    const ActionsFormatter = (props) => {
        const {
            item,
            onDelete = {},
            path = "",
            Link,
            showEdit = true, showView = true, showDelete = true
        } = props;
        return (
            <div className="bx-pull-right file-system">
                {
                    showView ?  <Link className="btn" to={ path + '/' + item.id + '/detail'}> <i className="bx bxs-show action-icon"/> </Link> 
                    : ""
                }
                {
                    showEdit ?  <Link className="btn" to={ path + '/' + item.id + '/edit'}> <i className="bx bxs-edit action-icon"/> </Link> 
                    : ""
                }
                {
                    showDelete ? <Link style={{marginLeft: "15px"}} className="btn" to="#" onClick={((event) => onDelete(event, item))}> <i className="bx bx-trash action-icon"/> </Link>
                    : ""
                }
            </div>
        )
    }
    const columns = useMemo(
        () => [
            {
                Header: "id",
                accessor: 'id',
                width: 100,
                disableFilters: true
            },
            {
                Header: t('CODE'),
                id: 'code',
                accessor: 'code',
            },
            {
                Header: t('DESCRIPTION'),
                id: 'description',
                accessor: 'description',
            },
            {
                Header: t('IS_ACTIVE'),
                id: 'isActive',
                accessor: 'isActive',
                Cell: ({ value }) => (value && value === 1 ? 
                    <span className="badge bg-success p-2">{t("ACTIVE")}</span> : 
                    <span className="badge bg-danger p-2">{t("INACTIVE")}</span>
                ),
            },
            {
                Header: t('ASSOCIATED_REFERENCE'),
                id: 'reference.name',
                accessor: 'reference.name',
            },
            {
                Header: t('ASSOCIATED_COMPANY'),
                id: 'company.name',
                accessor: 'company.name',
            },
            {
                Header: t('PARENT_VALUE_SAP'),
                id: 'parentReferenceValue.code',
                accessor: 'parentReferenceValue.code',
            },
            {
                Header: t('ASSOCIATED_LABELS'),
                id: 'labels',
                Cell: ({ row }) => (
                    <Link style={{marginLeft: "15px"}} className="btn" to={`/pages-references/${referenceId}/${row.original.id}`}>
                        <i className="bx bxs-show action-icon"/>
                    </Link>
                ),
            },
            {
                Header: '',
                id: 'actions',
                Cell: ({ row }) => (
                    <ActionsFormatter
                        item={row.original}
                        onDelete={(event, elt) => props.handleDelete(event, elt)}
                        path={`/pages-references/${referenceId}`}
                        Link={Link}
                        showEdit={true}
                        showView={true}
                        showDelete={false}
                    />
                ),
            },
        ],
        [t, props]
    );
    useEffect(() => {
        // load referencesValues from API
        async function getReferenceValues() {
            const response = await ReferenceValueRepository.getAll("", referenceId, authUser.companyId);
            console.log(response);
            setItems(response.items);
        }
        getReferenceValues();
        
    }, []);

    return (
        <div className="page-content">
            <title>{t("REFERENCE_VALUES")}</title>
            <LoadingContainer loading={isLoading}>
                <Card>
                    <CardBody>
                    <Row>
                                <Col xs="12">
                                    <div className="page-title-box justify-content-between">
                                        <div className="">
                                            <ol className="breadcrumb m-0">
                                                <BreadcrumbItem>
                                                    <Link style={{marginLeft: "15px"}} className="btn" to="/pages-references"> <i className='bx bx-chevron-left'></i> {t("REFERENCES")}</Link>
                                                </BreadcrumbItem>
                                                <BreadcrumbItem className="d-flex align-items-center">
                                                    <Link to="#"> {t("REFERENCE_VALUES")} </Link>                                                    
                                                </BreadcrumbItem>
                                            </ol>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        <TableContainer
                            columns={columns}
                            data={items}
                            isGlobalFilter={false}
                            isAddOptions={false}
                            isCustomPageSize={true}
                            customPageSize={10}
                            isPagination={true}
                        />
                    </CardBody>
                </Card>
            </LoadingContainer>
           
        </div>
    );
};

export default PagesReferenceValues;