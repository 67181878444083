import React, { useEffect,useCallback,useMemo,useState } from "react";
import { UsersListComponent } from "ppm-react-authentication";
import { USERS_API_URL } from "../../helpers/api-urls";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import AuthorizationChecker from "security/AuthorizationChecker";
import { useTranslation,withTranslation } from "react-i18next";
import LoadingContainer from "../Utility/Helpers/LoadingContainer";
//import TableContainer from "./utils/TableContainer";
import TableContainer from "../Utility/Helpers/TableContainer";
import {DIALOG_TYPE, HTTP_RESPONSE} from "../Utility/Helpers/constants";
import DialogBoxComponent from "../Utility/Helpers/DialogBoxComponent";
import UsersRepository from "./repository/users-repository";
import ActionsFormatter from "./utils/ActionsFormatter";
const PagesUsers = (props) => {
    const { t } = useTranslation();
    const [users, setUsers] = useState([]);


    const columns = useMemo(() => [
        {
            Header: props.t("_ID_"),
            accessor: "id",
            id: 'id',
            disableFilters: true

        },
        {
          Header: props.t("NAME"),
          id : 'name',
          accessor: 'lastname',
        },
        {
          Header: props.t("FIRST_NAME"),
          id : 'firstname',
          accessor: 'firstname',
        },
       {
          Header: props.t("COMPANY_NAME"),
          id: 'companyName',
          accessor: 'companyName',
        },
        {
          Header: props.t("INITIALS"),
          id : 'initials',
          accessor: 'initials',
        },
        {
          Header: props.t("EMAIL"),
          id : 'email',
          accessor: 'email',
        },
        {
          Header: props.t("USERNAME"),
          id : 'username',
          accessor: 'username',
        },
        {
          Header: props.t("STATUS"),
          id : 'enabled',
          accessor: 'enabled',
          Cell: ({ row }) => (
            row.original.enabled == 'active' 
              ? <span className="badge bg-success p-2">{props.t("ACTIVE")}</span>
              : <span className="badge bg-danger p-2">{props.t("INACTIVE")}</span>
          ),
        },
        {
          Header: props.t("LAST_LOGIN"),
          id : 'lastLogin',
          accessor: 'lastLogin',
          Cell: ({ row }) => (
            row.original.lastLogin 
              ? row.original.lastLogin.replace("T", " ").replace("Z", "").substring(0, 16)
              : "N/A"
          ),
        },
        {
          Header: props.t("LANGUAGE"),
          id: 'langue',
          accessor: 'langue',
        },
        {
          Header: 'ACTIONS',
          id: 'actions',
          Cell: ({ row }) => (
            <ActionsFormatter
              item={row.original}
              onDelete={(event, elt) => handleDelete(event, elt)}
              path={props.path}
              Link={props.Link}
              showEdit={true}
              showView={false}
              showDelete={false}
            />
          ),
        },
      ],
      [users,props]
    );

useEffect(() => {
    //find all user from users repository
    UsersRepository.findAll().then((response) => {
        console.log(response);
        if (response.status === HTTP_RESPONSE.SUCCESS) {
            console.log("OK: fetched users successfully");
            //loop through the response and set the status  1 to active and 0 to inactive
            response.items.map((item) => {
                if (item.enabled === 1) {
                    item.enabled = 'active';
                } else {
                    item.enabled = 'inactive';
                }
            });
            setUsers(response.items);
        }
    }).catch((error) => {
        console.error(error);
    });
}, []);



    return (
        <div className="page-content">
            <title>{t("USERS")}</title>
            <TableContainer
            columns={columns}
            data={users}
            isGlobalFilter={false}
            isAddOptions={false}
            isCustomPageSize={true}
            customPageSize={10}
            isPagination={true}
            theadClass="table-light"
            paginationDiv="col-12"
            isAddButton={false}
            //handleUserClick={handleCustomerClicks}
            buttonClass="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2 addCustomers-modal"
            buttonName=""
            paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
            tableClass="align-middle table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
            pagination="pagination"
            SearchPlaceholder="search..."
            />
        </div>
    );
};

PagesUsers.propTypes = {
  t: PropTypes.func,  
};

export default withTranslation()(PagesUsers);