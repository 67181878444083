import React from "react";
import {Link, useParams} from "react-router-dom";

import {ReferenceValueViewComponent} from "ppm-react-references";
import {REFERENCE_VALUES_API_URL} from "../../helpers/api-urls";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import AuthorizationChecker from "security/AuthorizationChecker";
import { withTranslation } from "react-i18next";

const PagesDetailReferenceValues = (props) =>{
    const {referenceId,referenceValueId} = props;
    const baseUrl = "/pages-references/"+referenceId
    return ( 
        <>
            <div className="page-content">
                    <title>{props.t("REFERENCE_VALUES")}</title>
                
                <div className="mb-4">
                    <Breadcrumbs title={props.t("REFERENCE_VALUES")} breadcrumbItem={props.t("REFERENCE_VALUES_DETAIL")}/>
                    <Link className="btn btn-info" to={baseUrl}>{props.t("RETURN_TO_LIST")}</Link>

                    { AuthorizationChecker.isGranted(["EDIT_REFERENCE_VALUES_TEMP"]) && ( <Link className="btn btn-info ms-3" to={baseUrl+"/"+referenceValueId+"/edit"}>{props.t("EDIT")}</Link> )}
                    
                    { AuthorizationChecker.isGranted(["ADD_REFERENCE_VALUES_TEMP"]) && (<Link className="btn btn-info ms-3" to={baseUrl+"/add"}>{props.t("ADD")}</Link> )}
                </div>
                <ReferenceValueViewComponent
                    referenceValueId={referenceValueId}
                    apiUrl={REFERENCE_VALUES_API_URL}
                    addUrl={baseUrl+"/add"}
                    path={baseUrl}
                    referenceId={referenceId}
                />
            </div>
        </>
    );
}

export default withTranslation()(PagesDetailReferenceValues)
