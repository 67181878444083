import React from "react"
import { Navigate } from "react-router-dom";
// // Pages Component
//import Chat from "../pages/Chat/Chat"

// // File Manager
//import FileManager from "../pages/FileManager/index"
import VendorFiles from "pages/FileManager/VendorFiles"
// Profile
import UserProfile from "../pages/Authentication/userprofile"

// Pages Calendar
//import Calendar from "../pages/Calendar/index"

// //Tasks
//import TasksList from "../pages/Tasks/tasks-list"
//import TasksKanban from "../pages/Tasks/tasks-kanban"
//import TasksCreate from "../pages/Tasks/tasks-create"

// //Ecommerce Pages
/*import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/index"
import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail"
import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index"
import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index"
import EcommerceCart from "../pages/Ecommerce/EcommerceCart"
import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout"
import EcommerceShops from "../pages/Ecommerce/EcommerceShops/index"
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct"

//Email
import EmailInbox from "../pages/Email/email-inbox"
import EmailRead from "../pages/Email/email-read"
import EmailBasicTemplte from "../pages/Email/email-basic-templte"
import EmailAlertTemplte from "../pages/Email/email-template-alert"
import EmailTemplateBilling from "../pages/Email/email-template-billing"

//Invoices
import InvoicesList from "../pages/Invoices/invoices-list"
import InvoiceDetail from "../pages/Invoices/invoices-detail"
*/
// Authentication related pages
//import Login from "../pages/Authentication/Login"
import NewLogin from "../pages/Authentication/NewLogin"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ChangePasswordPage from "../pages/Authentication/ChangePassword"
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail"


import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"
// Dashboard
import Dashboard from "../pages/Dashboard/index"

import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"
import PagesUserEdit from "../pages/Users/pages-edit-users";
import PagesUserAdd from "../pages/Users/pages-add-user";

import PagesUsers from "../pages/Users/pages-users";
import RegisterPage from "../pages/Users/register-page";
import PagesDetailPermissions from "pages/Permissions/pages-detail-permissions"
import PagesEditPermissions from "pages/Permissions/pages-add-permissions"
import PagesPermissions from "pages/Permissions/pages-permissions"
import PagesDetailRoles from "pages/Roles/pages-detail-roles"
import PagesEditRoles from "pages/Roles/pages-add-roles"
import PagesRoles from "pages/Roles/pages-roles"
import PagesEditCompanyUsers from "pages/CompanyUser/pages-add-company-users"
import PagesCompanyUsers from "pages/CompanyUser/pages-company-users"
import PagesDetailCompanies from "pages/Company/pages-detail-companies"
import PagesEditCompanies from "pages/Company/pages-add-companies"
import PagesCompanies from "pages/Company/pages-companies"

//import TodoListPage from "pages/Todo/TodoListPage"
import ReferenceWrapper from "pages/ReferenceWrapper/ReferenceWrapper"
import ProductWrapper from "pages/Products/ProductWrapper"
import pageSupplierAssign from "pages/Supplier/page-supplier-assign"
import PagesSupplier from 'pages/Supplier/page-supplier'
import UpcUpload from 'pages/Products/upc-upload'
import UpcList from 'pages/Products/upc_list'
import supplierUpload from "pages/Supplier/supplier-upload"
import SuppliersList from "pages/Supplier/suppliers_list" 
import referenceUpload from "pages/References/reference-upload"
import ConfigList from "pages/Settings/page-settings"


const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
//  { path: "/dashboard-saas", component: DashboardSaas },
//  { path: "/dashboard-crypto", component: DashboardCrypto },
//  { path: "/blog", component: Blog },

  //Gestion des references  
  { path: "/pages-references", component: ReferenceWrapper },
  { path: "/pages-references/:referenceId/", component: ReferenceWrapper },
  { path: "/pages-references/:referenceId/:referenceValueId/", component: ReferenceWrapper },  
  { path: "/pages-references/:referenceId/:referenceValueId/:referenceLabelId/", component: ReferenceWrapper },
  { path: "/pages-references/:referenceId/:referenceValueId/:referenceLabelId/:referenceLabelAction", component: ReferenceWrapper },
  { path: "/references/upload", component: referenceUpload },

  //Gestion des projects  
  { path: "/pages-projects", component: ProductWrapper },
  { path: "/pages-projects/:projectId/", component: ProductWrapper },
  { path: "/pages-projects/:projectId/:productId/", component: ProductWrapper },  
  { path: "/pages-projects/:projectId/:productId/:productAction", component: ProductWrapper },  
  { path: "/upc-upload", component: UpcUpload},
  { path: "/upc-list", component: UpcList},
  //supplier
  {path: "/supplier/:id/assign", component: pageSupplierAssign},
  {path: "/supplier", component: PagesSupplier},
  {path: "/supplier/upload", component: supplierUpload},
  {path: "/supplier/list", component: SuppliersList},
  //profile
  { path: "/profile", component: UserProfile },
  //File Manager
  { path: "/filemanager/vendorfiles", component: VendorFiles },
  //configurations
  { path : "/settings", component : ConfigList},

  
  //Gestion des utilisateurs
  { path: "/pages-users", component: PagesUsers },
  { path: "/pages-users/:id/edit", component: PagesUserEdit },
  { path: "/pages-users/add", component: PagesUserAdd },

   //Gestion des permissions
   { path: "/pages-permissions", component: PagesPermissions },
   { path: "/pages-permissions/:id/edit", component: PagesEditPermissions },
   { path: "/pages-permissions/add", component: PagesEditPermissions },
   { path: "/pages-permissions/:id/detail", component: PagesDetailPermissions },

  //Gestion des roles
  { path: "/pages-roles", component: PagesRoles },
  { path: "/pages-roles/:id/edit", component: PagesEditRoles },
  { path: "/pages-roles/add", component: PagesEditRoles },
  { path: "/pages-roles/:id/detail", component: PagesDetailRoles },

  // User Company
  { path: "/pages-company-users-roles", component: PagesCompanyUsers },
  { path: "/pages-company-users-roles/:id/edit", component: PagesEditCompanyUsers },
  { path: "/pages-company-users-roles/add", component: PagesEditCompanyUsers },

  //Gestion des compagnies
  { path: "/pages-companies", component: PagesCompanies },
  { path: "/pages-companies/:id/edit", component: PagesEditCompanies },
  { path: "/pages-companies/add", component: PagesEditCompanies },
  { path: "/pages-companies/:id/detail", component: PagesDetailCompanies },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Navigate to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: NewLogin },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register-page", component: Register },
  { path: "/register", component: RegisterPage },

  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
  { path: "/page-confirm-mail/:code", component: ConfirmMail },
  { path: "/page-confirm-lost-password/:code", component: ChangePasswordPage },
  { path: "/auth-email-verification", component: EmailVerification },
 // { path: "/crypto-ico-landing", component: CryptoIcoLanding },

 
]

export { authProtectedRoutes, publicRoutes }
