import React from "react";
import {Link, useParams} from "react-router-dom";

import {PermissionViewComponent} from "ppm-react-authentication";
import {PERMISSIONS_API_URL} from "../../helpers/api-urls";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import AuthorizationChecker from "security/AuthorizationChecker";
import { withTranslation } from "react-i18next";

const PagesDetailPermissions = (props) =>{
    const params = useParams();
    const {id = 0} = params;

    return ( 
        <>
            <div className="page-content">
                    <title>{props.t("PERMISSION")}</title>
                
                <div className="mb-4">
                    <Breadcrumbs title="Permission" breadcrumbItem={props.t("PERMISSION_DETAIL")}/>
                    <Link className="btn btn-info" to={"/pages-permissions"}>{props.t("RETURN_TO_LIST")}</Link>

                    { AuthorizationChecker.isGranted(["EDIT_PERMISSIONS"]) && ( <Link className="btn btn-info ms-3" to={"/pages-permissions/"+id+"/edit"}>{props.t("EDIT")}</Link> )}
                    
                    { AuthorizationChecker.isGranted(["ADD_PERMISSIONS"]) && (<Link className="btn btn-info ms-3" to={"/pages-permissions/add"}>{props.t("ADD")}</Link> )}
                </div>
                <PermissionViewComponent
                    id={id}
                    apiUrl={PERMISSIONS_API_URL}
                    addUrl="/pages-permissions/add"
                    path="/pages-permissions"
                />
            </div>
        </>
    );
}

export default withTranslation()(PagesDetailPermissions)
