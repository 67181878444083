import React from "react";
import {Link, useParams} from "react-router-dom";

import {RoleEditComponent} from "ppm-react-authentication";
import {ROLES_API_URL, PERMISSIONS_API_URL,ROLES_PERMISSIONS_API_URL} from "../../helpers/api-urls";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next";

const PagesEditRoles  = (props) =>{
    const {history} = props;
    const params = useParams();
    const {id = 0} = params;
    const onEditSuccess = (response) => {
        history.push("/pages-roles");
    }
    return (
        <>
            <div className="page-content">
                    <title>{props.t("ROLE")}</title>

                <div className="mb-4">
                    <Breadcrumbs title={props.t("ROLE")} breadcrumbItem={props.t("ROLE")+" - " + ( id ? props.t("EDIT") : props.t("ADD"))}/>
                    <Link className="btn btn-info" to={"/pages-roles"}>{props.t("RETURN_TO_LIST")}</Link>
                </div>
                <RoleEditComponent
                    id={id}
                    onEditSuccess={onEditSuccess}
                    apiUrl={ROLES_API_URL}
                    apiPermissionUrl={PERMISSIONS_API_URL}
                    apiRolePermissionUrl={ROLES_PERMISSIONS_API_URL}
                />
            </div>
        </>
    )
}

export default  withTranslation()(PagesEditRoles)
