/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState,useMemo } from "react"
import PropTypes from 'prop-types';
import {
    Container,
    Button,
} from "reactstrap"

import LoadingContainer from "../Utility/Helpers/LoadingContainer";
import TableContainer from "../Utility/Helpers/TableContainer";
import {DIALOG_TYPE, HTTP_RESPONSE} from "../Utility/Helpers/constants";
import DialogBoxComponent from "../Utility/Helpers/DialogBoxComponent";
import "../Utility/Helpers/style.css";
import SkuRepository from "./Repository/sku-repository";
import AuthorizationChecker from "../../security/AuthorizationChecker";
import { withTranslation } from "react-i18next";
import UserProvier from "security/UserProvier";


const ActionsFormatterUpcList = (props) => {
    const {user,enableEdit = false,upc,deletRole = "BUYER_APPROVE_VENDOR"} = props;
    return <div className="bx-pull-right file-system">
        {(enableEdit && AuthorizationChecker.isGranted([deletRole])) &&
        <Button onClick={(e) => props.onClick(e,upc)} color="danger" className="btn btn-danger btn-sm">
            <i className="bx bx-trash"></i>
        </Button>
    }
    </div>
}
        

const UpcList = (props) => {
    const BtnActions = (props) => {

        
        return (
            <ActionsFormatterUpcList
                user={user}
                upc={props.row.original}
                onClick={onDelete}
                enableEdit={(props.row.original.product_count > 0 ) ? false : true}
            />
        );
    }

    const [data, setData] = useState([]);
    const user = UserProvier.get();
    const columns = useMemo(() => [
        {
            Header: props.t("_ID_"),
            accessor: "id",
            disableFilters: true

        },
        {
            Header: props.t("_SKU_CODE_"),
            accessor: "sku"
        },
        {
            Header: props.t("_SAP_CODE_"),
            accessor: "code_sap"
        },
        {
            Header: props.t("PRODUCT_COUNT"),
            accessor: "product_count",
            disableFilters: true,
            Cell: ({ value }) => {
                if (value > 1) {
                    return <span className="badge bg-danger badge-pill">{value}</span>;
                } else {
                    return <span className="badge bg-success badge-pill">{value}</span>;
                }
            }
        },
        {
            Header: props.t("PROJECT_NAME"),
            accessor: "projectName",
            Cell: ({ value }) => {
                if (value.length > 0) {
                    if (value.length == 1)
                        {
                            return <span className="badge bg-success badge-pill">{value[0]}</span>;
                        }
                        else{
                            let cell ="";
                            for (let i = 0; i < value.length; i++) {
                                cell += value[i];
                                if (i < value.length - 1) {
                                    cell += ", ";
                                }
                            }
                            return <span className="badge bg-danger badge-pill">{cell}</span>;

                        }
                } else {
                    return <span className="badge bg-info badge-pill">N/A</span>;
                }
            }
        },
        {
            Header: props.t("_ACTIONS_"),
            accessor: "actions",
            Cell: BtnActions,
            disableFilters: true
        }
    ], []);


    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(15);
    const [search, setSearch] = useState('');
    const [fkCieId, setFkCieId] = useState(UserProvier.get().companyId);
    const [selected, setSelected] = useState([]);

    const [showDialog, setShowDialog] = useState(false);
    const [dialogType, setDialogType] = useState(DIALOG_TYPE.INFO);
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialog, setDialog] = useState({
        show: false,
        onConfirm: () => {
        },
        handleClose: () => {
        },
        message: "",
        type: DIALOG_TYPE.ALERT,
        title: ""
    });

    const closeDialogModal = () => {
        setDialog({...dialog, show: false});
        setLoading(false);
    };

    const catchError = (response) => {
        const currentDialog = {
            show: true,
            onConfirm: () => closeDialogModal(),
            handleClose: () => closeDialogModal(),
            type: DIALOG_TYPE.ALERT
        };
        let status = HTTP_RESPONSE.HTTP_NOT_FOUND;
        if (response.status != undefined) {
            status = response.status;
        } else if (response.error != undefined && response.error.statusCode != undefined) {
            status = response.error.statusCode;
        }
        switch (status) {
            case HTTP_RESPONSE.HTTP_BAD_REQUEST: {
                currentDialog.type = DIALOG_TYPE.ALERT;
                break;
            }
            case HTTP_RESPONSE.HTTP_NOT_FOUND: {
                currentDialog.type = DIALOG_TYPE.ALERT;
                break;
            }
            case HTTP_RESPONSE.HTTP_INTERNAL_SERVER_ERROR: {
                currentDialog.type = DIALOG_TYPE.SERVER_ERROR;
                currentDialog.message = '';
                if (response.data.message) {
                    currentDialog.message = response.data.message;
                }
                break;
            }
            case HTTP_RESPONSE.HTTP_UNAUTHORIZED: {
                dialog.type = DIALOG_TYPE.UNAUTHORIZED;
                dialog.message = response.data.error.message
                break;
            }
            case HTTP_RESPONSE.HTTP_UNPROCESSABLE_ENTITY: {
                currentDialog.type = DIALOG_TYPE.SERVER_ERROR;
                if( response.data.error.code=="ER_DUP_ENTRY"){
                    currentDialog.message = props.t("PROJECT_ALREADY_EXIST");
                }else{
                    currentDialog.message = props.t("CHECK_FORM_FEILDS");
                }
                break;
            }
            default:
        }
        setDialog(currentDialog);
    };


    useEffect(() => {

    const fetchData = async () => {
        try {
            const response = await SkuRepository.getAll(fkCieId);
            console.log(response);
            setData(response.skucodes);
            setTotal(response.total);
            setLoading(false);
        } catch (e) {
            if (e.status === HTTP_RESPONSE.HTTP_NOT_FOUND) {
                setError("No data found");
            } else {
                setError("An error occurred while fetching data");
            }
            setLoading(false);
        }
    }
    fetchData();
    }
    ,[]);

    const showSucessDialog = (message) => {
        const currentDialog = {
            show: true,
            onConfirm: () => closeDialogModal(),
            handleClose: () => closeDialogModal(),
            type: DIALOG_TYPE.SUCCESS,
            title: 'Success',
            message: message
        };
        setDialog(currentDialog);
    };
    const onDelete = async (e,upc) => {
        try {
            e.preventDefault();
            console.log(e);
           console.log(upc);
            const id = upc.id;
           // await SupplierRepository.delete(id);
           
           const currentDialog = {
                show: true,
                title: 'Delete SKU Code',
                onConfirm: async () => {
                    closeDialogModal();
                    setLoading(true);
                    await SkuRepository.delete(id).then(() => {
                            setData((prev) => prev.filter((i) => i.id !== id));
                            setTimeout(() => {
                            setLoading(false);
                        }, 1000);
                        showSucessDialog(props.t("DELETE_SKU_SUCCESS"));
                        
                    }).
                    catch((e) => catchError(e));
                    
                },
                handleClose: () => closeDialogModal(),
                type: DIALOG_TYPE.CONFIRM,
                message: props.t("DELETE_SKU_CONFIRMATION") + ' ' + upc.sku + '?'
            };
            setDialog(currentDialog);
           
         
        } catch (e) {
           const currentDialog = {
                show: true,
                onConfirm: () => closeDialogModal(),
                handleClose: () => closeDialogModal(),
                type: DIALOG_TYPE.ALERT,
                title: 'Error',
                message: props.t("DELETE_SKU_ERROR")
            };
            setDialog(currentDialog);

        }
    }

    
    
    return (
        <React.Fragment>
          <div className="page-content">
              <title>{props.t("_SKU_CODE_LIST_")}</title>
            <Container fluid={true}>
                <LoadingContainer loading={loading} error={error}>
                    <TableContainer
                            columns={columns}
                            data={data}
                            isGlobalFilter={false}
                            isAddOptions={false}
                            iscustomPageSizeOptions={true}
                            customPageSize={50}
                            isPagination={true}
                            tableClass="align-middle table-nowrap table-check table"
                            theadClass="table-light"
                            paginationDiv="col-12"
                            pagination="justify-content-center pagination pagination-rounded"
                        />
                </LoadingContainer>
            </Container>
            </div>

            <DialogBoxComponent
                    handleClose={() => closeDialogModal()}
                    handleConfirm={() => dialog.onConfirm()}
                    title={dialog.title}
                    show={dialog.show}
                    type={dialog.type}
                    message={dialog.message}
                />
            </React.Fragment>
    
                )

}    


UpcList.propTypes = {
    t: PropTypes.func
};
export default withTranslation()(UpcList);