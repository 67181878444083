import React from 'react'
import {Link, useLocation} from "react-router-dom";
import AuthorizationChecker from "../../../security/AuthorizationChecker";

const ActionsFormatter = (props) => {
    // eslint-disable-next-line react/prop-types
    const {item, onDelete = {}, enableView=true, enableEdit = true, enableRemove = true, editRole = "ROLE_USER", deleteRole = "ROLE_USER", location = useLocation(),isProjects=false} = props;
    return <div className="bx-pull-right file-system">

       { enableView && <Link className="btn" to={ (location.pathname ? location.pathname :location ) + '/' + item.id + '/detail'}><i className="bx bxs-detail action-icon"/></Link>}

        {(enableEdit && AuthorizationChecker.isGranted([editRole])) &&
        <Link className="btn" to={ (location.pathname ? location.pathname :location ) + '/' + item.id + '/edit'}><i className="bx bxs-edit action-icon"/></Link>}

        {(enableRemove && AuthorizationChecker.isGranted([deleteRole])) &&
        <a style={{marginLeft: "15px"}} className="btn" href="#" onClick={(event => onDelete(event, item))}><i
            className="bx bx-trash action-icon"/></a>}

    {isProjects && <Link className="btn" to={ (location.pathname ? location.pathname :location ) + '/' + item.id }><i className="bx bx-show action-icon"/></Link>}

        
    </div>
}

export default ActionsFormatter
