import React, { useEffect, useState, useMemo } from "react"

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';


import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
const { ExportCSVButton } = CSVExport;
const ExportBtn = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button className="btn btn-success" onClick={ handleClick }>{props.t("_EXPORT_CSV_")}</button>
      </div>
    );
  };

ExportBtn.prototype = {
    t: PropTypes.func
}
export default withTranslation()(ExportBtn);
