import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Badge, Container, Row, Col, Card, CardBody, BreadcrumbItem } from "reactstrap";
import { Input } from "reactstrap";
import { Link } from "react-router-dom";
import LoadingContainer from "../Utility/Helpers/LoadingContainer";
import { DIALOG_TYPE, HTTP_RESPONSE } from "../Utility/Helpers/constants";
import DialogBoxComponent from "../Utility/Helpers/DialogBoxComponent";
import "../Utility/Helpers/style.css";
import ProjectsRepository from "./Repository/project-repository";
import TableContainerGrid from "pages/Utility/Helpers/TableContainerGrid";

import PaginationWrap from "../Utility/Helpers/PaginationWrap";
//import TableContainer from "components/Common/Grid/TableContainer";
import TableContainer from "../Utility/Helpers/TableContainer";
import ActionsFormatter from "../Utility/Helpers/ActionsFormatter";
import AuthorizationChecker from "../../security/AuthorizationChecker";
import UserProvier from "security/UserProvier";
import { withTranslation,useTranslation } from "react-i18next";
import  Select  from "react-select";
const PagesProjectsList = ( props ) => {
    const [CORRECT_STATUS, setCorrectStatus] = useState([]);
    const [projectStatus, setProjectStatus] = useState({});
    const [items, setItems] = useState([]);
    const [selectedProjectsIds, setSelectedProjectsIds] = useState([]);
    const [unSelectableProjectsIds, setUnSelectableProjectsIds] = useState([]);
    const [hiddenColumns, setHiddenColumns] = useState([]);
    const [errors, setErrors] = useState([]);
    const [total, setTotal] = useState(0);
    const [entity, setEntity] = useState(null);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const [loadingSearch, setLoadingSearch] = useState(true);
    const [query, setQuery] = useState("");
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [companyId] = useState(UserProvier.get().companyId);
    const { t } = useTranslation();
    
    useEffect(() => {
        ProjectsRepository.getStatus()
        .then((data) => {
            console.log("Status:", data);
            
            //loop trought data and add status into projectStatus
            
            setProjectStatus(data); 
            
            setCorrectStatus(data["CORRECT_STATUS"]);
           
          
            })
            .catch((response) => {
                console.log("Failed loading status: ", response);
            });
    }, []);

   

    const selectAllProject = (event) => {
      //  console.log(projectStatus)
        let {checked} = event.target;
        let selectedProjects= []

        if(checked){
            selectedProjects = items.filter((p) => unSelectableProjectsIds.indexOf(p.id) === -1).map((e) => e.id);
        }
        console.log(selectedProjects)
        setSelectedProjectsIds(selectedProjects)
    }
    
   /* const selectProject = (id) => {
        console.log("selectProject",id)
        console.log("INDEX",unSelectableProjectsIds.indexOf(id))
        if (unSelectableProjectsIds.indexOf(id) >= 0) {
            console.log("dans les projets non selectionnables");
            catchError({
                status: HTTP_RESPONSE.HTTP_UNAUTHORIZED,
                data: {
                    error: {
                        message: "{\"message\":\"PROJECT_UNMODIFIABLE\",\"name\":\"PROJECT_AT_INCOMPATIBLE_STATE\"}"
                    }
                }
            });
            return;
        }
    //    console.log("selectedProjectsIds",selectedProjectsIds);
        let checked = selectedProjectsIds.indexOf(id) === -1;
       
        let selectedProjects = selectedProjectsIds;
        console.log("selectedProjectsIds",selectedProjects);
        if (checked) {
            selectedProjects.push(id);
        } else {
            selectedProjects = selectedProjects.filter((i) => i !== id);
        }
        console.log("selectedProjectsIds",selectedProjects);
        setSelectedProjectsIds(selectedProjects);
    }
*/
const selectProject = (id) => {
    console.log("selectProject", id);
    console.log("INDEX", unSelectableProjectsIds.indexOf(id));
    if (unSelectableProjectsIds.indexOf(id) >= 0) {
        console.log("dans les projets non selectionnables");
        catchError({
            status: HTTP_RESPONSE.HTTP_UNAUTHORIZED,
            data: {
                error: {
                    message: "{\"message\":\"PROJECT_UNMODIFIABLE\",\"name\":\"PROJECT_AT_INCOMPATIBLE_STATE\"}"
                }
            }
        });
        return;
    }

    setSelectedProjectsIds(prevSelectedProjects => {
        if (prevSelectedProjects.includes(id)) {
            return prevSelectedProjects.filter(projectId => projectId !== id);
        } else {
            return [...prevSelectedProjects, id];
        }
    });
};

    const parseState = (stateId,projectStatus) =>{
        if( Object.keys(projectStatus).indexOf(""+stateId) >= 0){
            return projectStatus[stateId]
        }else{
            return projectStatus[0]
        }
    }


/**
 * Toggles the visibility of the dialog modal and sets loading to false.
 * 
 * @function
 * @name toggleDialogModal
 */
const toggleDialogModal = () => {
    setLoading(false);
    setDialog(prevDialog => ({
        ...prevDialog,
        show: !prevDialog.show
    }));
};
/**
 * Assigns a project to the current user.
 * 
 * This function updates the component's state to show loading, then calls the
 * ProjectsRepository to assign the specified project. After successful assignment,
 * it refreshes the list of projects. If an error occurs during the process, it
 * catches and handles the error.
 *
 * @param {number|string} projectId - The unique identifier of the project to be assigned.
 * @returns {Promise<void>} A promise that resolves when the operation is complete
 */
const assignProject = async (projectId) => {
    setLoading(true);
    try {
        await ProjectsRepository.assignProject(projectId);
        await findAll();
    } catch (error) {
        catchError(error);
    } finally {
        setLoading(false);
    }
};



const handleDelete = (e, elt) => {
    e.preventDefault();
    setEntity(elt);
    setDialog({
        ...dialog,
        onConfirm: ()=> performDelete(elt),
        handleClose: ()=> closeDialogModal(),
        type: DIALOG_TYPE.CONFIRM_DELETION
    });
    toggleDialogModal();
}

const performDelete = (entityToDelete) => {
    if (entityToDelete && entityToDelete.id) {
        setLoading(true);
        ProjectsRepository.remove(entityToDelete.id)
            .then(data => {
                console.log(data);
                setItems(prevItems => prevItems.filter(elt => elt.id !== entityToDelete.id));
                setTotal(prevTotal => prevTotal - 1);
                setEntity(null);
                toggleDialogModal();
                onAllDoneSuccessfully();
            })
            .catch(response => {
                console.log(response);
                catchError(response);
            })
            .finally(() => {
                setLoading(false);
            });
    }
};

const onAllDoneSuccessfully = () => {
    setDialog(prevDialog => ({
        ...prevDialog,
        show: true,
        handleClose: () => closeDialogModal(),
        type: DIALOG_TYPE.SUCCESS,
        message: t("SUCCESSFULLY_DELETED")
    }));
};

    const BtnActions = (props) => {

        
        let item_row=props.row.original
        let canSelfAssign = false;
        if (AuthorizationChecker.isGranted(["BUYER_EDIT_PROJECT"]))
            {
                if (!item_row.purchaserId)
                    {
                        canSelfAssign = true;
                    }
            }
        else if (AuthorizationChecker.isGranted(["ECOM_EDIT_PROJECT"]))
        {
            if (!item_row.ecommId)
                {
                    canSelfAssign = true;
                }
        }
        return (
            <ActionsFormatter
            item={item_row}
            editRole={
                AuthorizationChecker.isGranted(["VENDOR_READ_PROJECT"]) ? "VENDOR_EDIT_PROJECT" :
                AuthorizationChecker.isGranted(["BUYER_READ_PROJECT"]) ? "BUYER_EDIT_PROJECT" :
                AuthorizationChecker.isGranted(["MD_READ_PROJECT"]) ? "MD_EDIT_PROJECT" : 
                AuthorizationChecker.isGranted(["ECOM_READ_PROJECT"]) ? "ECOM_EDIT_PROJECT" : 
                ""
            }
            enableRemove = {
                AuthorizationChecker.isGranted(["VENDOR_EDIT_PROJECT"]) ? (CORRECT_STATUS.indexOf(item_row.statusId) >=0) :
                AuthorizationChecker.isGranted(["BUYER_EDIT_PROJECT"]) ?  ( (CORRECT_STATUS.indexOf(item_row.statusId) >=0) && item_row.purchaserId==UserProvier.get().id) : true
            }
            enableEdit={
                AuthorizationChecker.isGranted(["VENDOR_READ_PROJECT"]) ? (CORRECT_STATUS.indexOf(item_row.statusId) >=0) :
                AuthorizationChecker.isGranted(["BUYER_READ_PROJECT"]) ?  ( (CORRECT_STATUS.indexOf(item_row.statusId) >=0) && item_row.purchaserId==UserProvier.get().id) :
                AuthorizationChecker.isGranted(["MD_READ_PROJECT"]) ?  ( (CORRECT_STATUS.indexOf(item_row.statusId) >=0) && item_row.dmId==UserProvier.get().id) :
                AuthorizationChecker.isGranted(["ECOM_READ_PROJECT"]) ?  ( (CORRECT_STATUS.indexOf(item_row.statusId) >=0) && item_row.ecommId==UserProvier.get().id) : true
            }
            isProjects={true}
            onDelete={(event, elt) => handleDelete(event, elt)}/>
        );
    }


   
    
    const onPageChange = (page) => {
        setPageSizeOptions((prevState) => ({
            ...prevState,
            page,
        }));
       // findAll(page);
    };

    const handleSearchChange = (value) => {
        setQuery(value);
        setLoadingSearch(true);
        findAll();
    };
    const [pageSizeOptions, setPageSizeOptions] = useState({
        totalSize: 0,
        custom: true,
        onPageChange: (page) => onPageChange(page),
        onSearchChange: handleSearchChange,
        page: 1,
        sizePerPage: 10,
        pageStartIndex: 1,
        sizePerPageList: 10,
        showTotal: true,
        onSizePerPageChange: {},
    });
    const [dialog, setDialog] = useState({
        show: false,
        onConfirm: () => {},
        handleClose: () => {},
        message: "",
        type: DIALOG_TYPE.ALERT,
    });
  



    const findAll = (page = 1) => {
        //check if CORRECT_STATUS is empty or not if empty return
        if (CORRECT_STATUS.length == 0) {
            return;
        }
        ProjectsRepository.getList(query)
            .then((data) => {
                setItems(data.items);
                console.log(data);
                /*setUnSelectableProjectsIds(
                    data.items.filter((p) => CORRECT_STATUS.indexOf(p.statusId) < 0).map((p) => p.id)
                );*/
                let unSelectableProjects = data.items.filter((p) => {
                    console.log("STATUS :"+p.statusId+" ID : "+p.id);
                    console.log("CORRECT_STATUS",CORRECT_STATUS);
                    if (CORRECT_STATUS.indexOf(p.statusId) < 0 ) {
                        console.log("unsellable",p.id)
                        return p.id;
                    }
                });
                setUnSelectableProjectsIds(unSelectableProjects.map((p) => p.id));
                setTotal(data.totalCount);
                setPageSizeOptions((prevState) => ({
                    ...prevState,
                    sizePerPage: itemPerPage,
                    totalSize: 10,
                }));
                setLoading(false);
                setLoadingSearch(false);
                //test add hidden columns
                //setHiddenColumns(["checkbox","actions"]);
               //add with push hidden columns
               //if we have vendor, some columns should be hidden
               
               //if we have buyer, some columns should be hidden
               if (!AuthorizationChecker.isGranted(["BUYER_EDIT_PROJECT"]) && !AuthorizationChecker.isGranted(["MD_EDIT_PROJECT"]) &&
                !AuthorizationChecker.isGranted(["ECOM_READ_PROJECT"])) {
                if (companyId ==1){
                   setHiddenColumns(["assignedTo","purchaserName","dmName"]);
                }else if (companyId ==2){
                    setHiddenColumns(["assignedTo","purchaserName","dmName","level_1"]);
                }
               }else{
                if (companyId ==2){
                    setHiddenColumns(["level_1"]);
                }
               }
             


            })
            .catch((response) => {
                catchError(response);
            });
    };

    useEffect(() => {
        findAll();
    }, [CORRECT_STATUS]);
   

   const catchError = (response) => {
       setDialog(prevDialog => ({
           ...prevDialog,
           show: true,
           onConfirm: () => closeDialogModal(),
           handleClose: () => closeDialogModal(),
           type: DIALOG_TYPE.ALERT,
           message: ""
       }));
   
       switch (response.status) {
           case HTTP_RESPONSE.HTTP_BAD_REQUEST: {
               setDialog(prevDialog => ({ ...prevDialog, type: DIALOG_TYPE.ALERT }));
               break;
           }
           case HTTP_RESPONSE.HTTP_NOT_FOUND: {
               setDialog(prevDialog => ({
                   ...prevDialog,
                   type: DIALOG_TYPE.ALERT,
                   message: response.data.message || response.data.error.message
               }));
               break;
           }
           case HTTP_RESPONSE.HTTP_NOT_ACCEPTABLE: {
               setDialog(prevDialog => ({
                   ...prevDialog,
                   type: DIALOG_TYPE.NOT_ACCEPTABLE,
                   message: response.data.error.message
               }));
               break;
           }
           case HTTP_RESPONSE.HTTP_UNAUTHORIZED: {
               setDialog(prevDialog => {
                   let newDialog = { ...prevDialog, type: DIALOG_TYPE.UNAUTHORIZED };
                   try {
                       const err = JSON.parse(response.data.error.message);
                       console.log("Error:", err);
                       newDialog.message = t(err.message) + (err.name ? "->" + err.name : "");
                   } catch(e) {
                       newDialog.message = t(response.data.error.message || "");
                   }
                   return newDialog;
               });
               break;
           }
           case HTTP_RESPONSE.HTTP_INTERNAL_SERVER_ERROR: {
               setDialog(prevDialog => ({
                   ...prevDialog,
                   type: DIALOG_TYPE.SERVER_ERROR,
                   message: response.data.message || response.data.error.message
               }));
               break;
           }
           default:
       }
   };

   const closeDialogModal = () => {
    setDialog(prevDialog => ({ ...prevDialog, show: false }));
    setLoading(false);
};

   const isOwner = (row,user_id) => {
    return row.original.purchaserId == user_id || row.original.vendorId == user_id || row.original.dmId == user_id || row.original.ecommId == user_id
   }

   

  
    let user = UserProvier.get();
    
 
    /**
 * Sends selected projects to Mondou.
 * 
 * This function updates the component's state to show loading, then sends the
 * selected project IDs to Mondou using the ProjectsRepository. After successful
 * sending, it clears the selection and refreshes the project list.
 * 
 * @function
 * @name sendToMondou
 * @param {Array} selectedProjectsIds - Array of selected project IDs
 * @param {function} setLoading - Function to update loading state
 * @param {function} setSelectedProjectsIds - Function to update selected project IDs
 * @param {function} findAll - Function to refresh the project list
 * @param {function} catchError - Function to handle errors
 * @returns {Promise<void>} A promise that resolves when the operation is complete
 */
const sendToMondou = async () => {
    setLoading(true);
    try {
        await ProjectsRepository.sendToMondo(selectedProjectsIds);
        setSelectedProjectsIds([]);
        await findAll();
    } catch (error) {
        catchError(error);
    } finally {
        setLoading(false);
    }
};


/**
 * Sends selected projects back to the vendor.
 * 
 * This function updates the component's state to show loading, then sends the
 * selected project IDs back to the vendor using the ProjectsRepository. After 
 * successful sending, it clears the selection and refreshes the project list.
 * 
 * @function
 * @name sendBackToVendor
 * @returns {Promise<void>} A promise that resolves when the operation is complete
 */
const sendBackToVendor = async () => {
    setLoading(true);
    try {
        await ProjectsRepository.sendToVendor(selectedProjectsIds);
        setSelectedProjectsIds([]);
        await findAll();
    } catch (error) {
        catchError(error);
    } finally {
        setLoading(false);
    }
};

/**
 * Sends selected projects to DM (Decision Maker).
 * 
 * This function updates the component's state to show loading, then sends the
 * selected project IDs to DM using the ProjectsRepository. After successful
 * sending, it clears the selection and refreshes the project list.
 * 
 * @function
 * @name sendToDM
 * @returns {Promise<void>} A promise that resolves when the operation is complete
 */
const sendToDM = async () => {
    setLoading(true);
    try {
        await ProjectsRepository.sendToDM(selectedProjectsIds);
        setSelectedProjectsIds([]);
        await findAll();
    } catch (error) {
        catchError(error);
    } finally {
        setLoading(false);
    }
};

/**
 * Sends selected projects to the Ecom system.
 * 
 * This function updates the component's state to show loading, then sends the
 * selected project IDs to Ecom using the ProjectsRepository. After successful
 * sending, it clears the selection and refreshes the project list.
 * 
 * @function
 * @name sendToEcom
 * @returns {Promise<void>} A promise that resolves when the operation is complete
 */
const sendToEcom = async () => {
    setLoading(true);
    try {
        await ProjectsRepository.sendToEcom(selectedProjectsIds);
        setSelectedProjectsIds([]);
        await findAll();
    } catch (error) {
        catchError(error);
    } finally {
        setLoading(false);
    }
};

/**
 * Sends selected projects back to the buyer.
 * 
 * This function updates the component's state to show loading, then sends the
 * selected project IDs back to the buyer using the ProjectsRepository. After 
 * successful sending, it clears the selection and refreshes the project list.
 * If an error occurs during the process, it catches and handles the error.
 * 
 * @async
 * @function
 * @name sendBackToBuyer
 * @returns {Promise<void>} A promise that resolves when the operation is complete
 */
const sendBackToBuyer = async () => {
    try {
        setLoading(true);
        await ProjectsRepository.sendToBuyer(selectedProjectsIds);
        setSelectedProjectsIds([]);
        await findAll();
    } catch (error) {
        catchError(error);
    } finally {
        setLoading(false);
    }
};

/**
 * Exports selected projects for SAP.
 * 
 * This function updates the component's state to show loading, then exports
 * the selected project IDs for SAP using the ProjectsRepository. After successful
 * export, it creates a downloadable CSV file with the exported data, clears the
 * selection, and refreshes the project list. If an error occurs during the process,
 * it catches and handles the error.
 *
 * @async
 * @function
 * @name exportForSAP
 * @returns {Promise<void>} A promise that resolves when the operation is complete
 */
const exportForSAP = async () => {
    try {
        setLoading(true);
        const result = await ProjectsRepository.exportForSAP(selectedProjectsIds);
        setSelectedProjectsIds([]);

        const blob = new Blob([result.data]);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `project_exported_sap_${new Date().toISOString()}.csv`;
        link.click();

        await findAll();
    } catch (error) {
        catchError(error);
    } finally {
        setLoading(false);
    }
};

/**
 * Sends selected projects back to modification status.
 * 
 * This function updates the component's state to show loading, then sends the
 * selected project IDs back to modification status using the ProjectsRepository.
 * After successful modification, it clears the selection and refreshes the project list.
 * If an error occurs during the process, it catches and handles the error.
 * 
 * @function
 * @name backToModification
 * @returns {Promise<void>} A promise that resolves when the operation is complete.
 * @throws {Error} Catches and handles any errors that occur during the process.
 */
const backToModification = async () => {
    try {
        setLoading(true);
        await ProjectsRepository.backToModification(selectedProjectsIds);
        setSelectedProjectsIds([]);
        await findAll();
    } catch (error) {
        catchError(error);
    } finally {
        setLoading(false);
    }
};

/**
 * Confirms SAP creation for selected projects.
 * 
 * This function updates the component's state to show loading, then confirms
 * SAP creation for the selected project IDs using the ProjectsRepository.
 * After successful confirmation, it clears the selection and refreshes the project list.
 * If an error occurs during the process, it catches and handles the error.
 * 
 * @function
 * @name confirmSapCreation
 * @returns {Promise<void>} A promise that resolves when the operation is complete
 */
const confirmSapCreation = async () => {
    try {
        setLoading(true);
        await ProjectsRepository.confirmSapCreation(selectedProjectsIds);
        setSelectedProjectsIds([]);
        await findAll();
    } catch (error) {
        catchError(error);
    } finally {
        setLoading(false);
    }
};


/**
 * Exports selected projects to Ecom and optionally finishes the project.
 * 
 * This function updates the component's state to show loading, then exports
 * the selected project IDs to Ecom using the ProjectsRepository. If not finishing
 * the project, it creates a downloadable CSV file with the exported data.
 * After the export, it clears the selection and refreshes the project list.
 * 
 * @async
 * @function
 * @name exportToEcom
 * @param {boolean} [finishProject=false] - If true, marks the project as finished. If false, only exports the data.
 * @returns {Promise<void>} A promise that resolves when the operation is complete
 */
const exportToEcom = async (finishProject = false) => {
    try {
        setLoading(true);
        const result = await ProjectsRepository.exportToEcom(selectedProjectsIds, finishProject);
        setSelectedProjectsIds([]);

        if (!finishProject) {
            const blob = new Blob([result.data]);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `project_ecom_exported_${new Date().toISOString()}.csv`;
            link.click();
        }

        await findAll();
    } catch (error) {
        catchError(error);
    } finally {
        setLoading(false);
    }
};

/**
 * Sends selected projects to RenPet.
 * 
 * This function updates the component's state to show loading, then sends the
 * selected project IDs to RenPet using the ProjectsRepository. After successful
 * sending, it clears the selection and refreshes the project list.
 * 
 * @async
 * @function
 * @name sendToRenPet
 * @returns {Promise<void>} A promise that resolves when the operation is complete
 */
const sendToRenPet = async () => {
    try {
        setLoading(true);
        await ProjectsRepository.sendToRenPet(selectedProjectsIds);
        setSelectedProjectsIds([]);
        await findAll();
    } catch (error) {
        catchError(error);
    } finally {
        setLoading(false);
    }
};


/**
 * Exports selected projects to Microsoft.
 * 
 * This function updates the component's state to show loading, then exports
 * the selected project IDs to Microsoft using the ProjectsRepository. After 
 * successful export, it creates a downloadable CSV file with the exported data,
 * clears the selection, and refreshes the project list.
 * 
 * @async
 * @function
 * @name exportToMicrosoft
 * @returns {Promise<void>} A promise that resolves when the operation is complete
 * @throws {Error} Catches and handles any errors that occur during the export process.
 */
const exportToMicrosoft = async () => {
    try {
        setLoading(true);
        const result = await ProjectsRepository.exportToMicrosoft(selectedProjectsIds);
        setSelectedProjectsIds([]);

        const blob = new Blob([result.data]);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `project_exported_to_microsoft_${new Date().toISOString()}.csv`;
        link.click();

        await findAll();
    } catch (error) {
        catchError(error);
    } finally {
        setLoading(false);
    }
};

/**
 * Sends selected projects back to modification status for the buyer.
 * 
 * This function updates the component's state to show loading, then sends the
 * selected project IDs back to modification status using the ProjectsRepository.
 * After successful modification, it clears the selection and refreshes the project list.
 * If an error occurs during the process, it catches and handles the error.
 * 
 * @async
 * @function
 * @name buyerBackToModification
 * @returns {Promise<void>} A promise that resolves when the operation is complete
 * @throws {Error} Catches and handles any errors that occur during the process.
 */
const buyerBackToModification = async () => {
    try {
        setLoading(true);
        await ProjectsRepository.backToModification(selectedProjectsIds);
        setSelectedProjectsIds([]);
        await findAll();
    } catch (error) {
        catchError(error);
    } finally {
        setLoading(false);
    }
};


const columns =
useMemo(() => [
        {
            id: "checkbox",
            accessor: "",
            sortDescFirst: true, 
          //  Header: <input type="checkbox" id="0" onChange={e=>selectAllProject(e)}/>,
            
            Cell: ({ row }) => {
                let item = row.original;
                if (unSelectableProjectsIds.indexOf(item.id) >= 0) {
                    return (
                        <div className="form-check">
                            <Input
                                type="checkbox"
                                className="form-check-input"
                                id={item.id}
                                disabled
                            />
                            <label className="form-check-label" htmlFor={item.id}></label>
                        </div>
                    );
                } else {
                    return (
                        <div className="form-check">
                            <Input
                                type="checkbox"
                                className="form-check-input"
                                id={item.id}
                                onChange={() => selectProject(item.id)}
                            />
                            <label className="form-check-label" htmlFor={item.id}></label>
                        </div>
                    );
                }
            },
            disableFilters: true,
        },
        {
            Header: props.t("_ACTIONS_"),
            accessor: "actions",
            id: "actions",
            Cell: BtnActions,
            disableFilters: true,
            
        },
        {
            Header: props.t("SELF_ASSIGN"),
            accessor: "assignedTo",
            hidden : true,
            id: "assignedTo",
            Cell: ({ row }) => {
                let item = row.original;
                let user_id = UserProvier.get().id;
                if (AuthorizationChecker.isGranted(["BUYER_EDIT_PROJECT"]))
                {
                    if (!item.purchaserId)
                    {
                        return (
                            <a style={{marginLeft: "15px"}} className="btn" href="#" onClick={(event) => assignProject(item.id)}>
                                <i className='bx bxs-hand-up action-icon text-success'></i>
                            </a> 
                        ) 
                    } 
                    else{
                        if (item.purchaserId === user_id)
                        {
                            return (
                                <a style={{marginLeft: "15px"}} className="btn" href="#">
                                    <i className='bx bx-check action-icon  text-info'></i>
                                </a> 
                            )
                        }
                        else{
                            return (
                                <a style={{marginLeft: "15px"}} className="btn" href="#">
                                    <i className='bx bx-block action-icon text-danger'></i>
                                </a> 
                            )
                        }
                    }
                }
                if (AuthorizationChecker.isGranted(["MD_EDIT_PROJECT"]))
                {
                    if (!item.dmId)
                    {
                        return (
                            <a style={{marginLeft: "15px"}} className="btn" href="#" onClick={(event) => assignProject(item.id)}>
                                <i className='bx bxs-hand-up action-icon text-success'></i>
                            </a> 
                        ) 
                    } 
                    else{
                        if (item.dmId === user_id)
                        {
                            return (
                                <a style={{marginLeft: "15px"}} className="btn" href="#">
                                    <i className='bx bx-check action-icon  text-info'></i>
                                </a> 
                            )
                        }
                        else{
                            return (
                                <a style={{marginLeft: "15px"}} className="btn" href="#">
                                    <i className='bx bx-block action-icon text-danger'></i>
                                </a> 
                            )
                        }
                    }
                }
                if(AuthorizationChecker.isGranted(["ECOM_READ_PROJECT"]))
                {
                    if (!item.ecommId)
                    {
                        return (
                            <a style={{marginLeft: "15px"}} className="btn" href="#" onClick={(event) => assignProject(item.id)}>
                                <i className='bx bxs-hand-up action-icon text-success'></i>
                            </a> 
                        ) 
                    }
                    else{
                        if (item.ecommId === user_id)
                        {
                            return (
                                <a style={{marginLeft: "15px"}} className="btn" href="#">
                                    <i className='bx bx-check action-icon  text-info'></i>
                                </a> 
                            )
                        }
                        else{
                            return (
                                <a style={{marginLeft: "15px"}} className="btn" href="#">
                                    <i className='bx bx-block action-icon text-danger'></i>
                                </a> 
                            )
                        }
    
                    }
                }
            },
            disableFilters: true,
            
        },
        {
            Header: props.t("LEVEL_1"),
            id: "level_1",
            accessor: "niveau1.description",
            Cell: ({ row }) => {
                let item = row.original;
                return item.niveau1?.description || '';
            }

        },
        {
            Header: props.t("VENDOR"),
            id: "vendorName",
            accessor: "vendorName",
            Cell: ({ row }) => {
                let item = row.original;
                return item.vendorName;
            }
        },
       
        {
            Header: props.t("PROJECT_NAME"),
            id: "name",
            accessor: "name",
            Cell: ({ row }) => {
                let item = row.original;
                return item.name;
            }
           
        },
       
        {
            Header : props.t("STATUS"),
            id: "statusId",
            accessor: "statusId",
            Cell: ({ row }) => {
                //get the project status state list and store it into statuss
                //assign the color base on these conditions
                //if the statusId is in the correct status list, and the user.id is the same as the purchaserId or vendorId or dmId or ecommId, the color will be green
                //if the statusId is not in the correct status list,  and the user.id is the same as the purchaserId or vendorId or dmId or ecommId, the color will be yellow (warning)
                //if the user.id is not the same as the purchaserId or vendorId or dmId or ecommId, the color will be red
                //if statusId is Finished, the color will be blue
                let color ="";
                let user_id = UserProvier.get().id;
                if ((CORRECT_STATUS.indexOf(row.original.statusId) >= 0)) {
                    //if the user.id is the same as the purchaserId or vendorId or dmId or ecommId
                    if (isOwner(row,user_id) ) {
                        color = "success"
                    }else{
                        if ((AuthorizationChecker.isGranted(["BUYER_READ_PROJECT"]) && !row.original.purchaserId) ||
                            (AuthorizationChecker.isGranted(["MD_READ_PROJECT"]) &&!row.original.dmId) ||
                            (AuthorizationChecker.isGranted(["ECOM_READ_PROJECT"]) &&!row.original.ecommId)) 
                            {
                                color = "success"
                            }
                            else
                            {
                                color = "danger"
                            }
                    }
                }else{
                    if (isOwner(row,user_id)) {
                        color = "warning"
                    }else{
                        color = "danger"
                    }
                    //check if status is Finished
                    let status = parseState(row.original.statusId,projectStatus)
                    if (status == "Finished") {
                        color = "primary"
                    }
                }

               
                return <Badge className={"font-size-12 badge-" + color}
                color={color}
                pill>{parseState(row.original.statusId,projectStatus)}</Badge>;
                
            },
            meta: {
                filterVariant: 'select',
              },
        },
        {
            Header: props.t("PURCHASER"),
            id: "purchaserName",
            hidden : true,
            accessor: "purchaserName",
        
        },
        {
            Header: props.t("DM"),
            id: "dmName",
            hidden : true,
            accessor: "dmName",
        },
        {
            Header: props.t("LAST_COMMENT"),
            id: "lastComment",
            accessor: "lastComment",
            Cell: ({ row }) => {
                let item = row.original;
                if (!item.lastComment.comments)
                    {
                        return null;
                    }
                if (item.lastComment.comments && item.lastComment.isPrivate == 0)
                    {
                        return (<div className="flex-grow-1">
                            <p className="text-muted mb-0">
                                <i className="mdi mdi-calendar me-1">  </i>
                                {item.lastComment.createdAt}
                            </p>
                            <h5 className="font-size-13 mb-1">{item.lastComment.user.firstname} {item.lastComment.user.lastname}</h5>
                            <p className="text-muted mb-1  ms-3" style={{ wordWrap: "break-word" }}>
                                <span dangerouslySetInnerHTML={{ __html: item.lastComment.comments }} />
                            </p>
                        </div>)
                    }
                if ((item.lastComment.comments && item.lastComment.isPrivate == 1) &&  (
                    AuthorizationChecker.isGranted(["BUYER_READ_PROJECT"]) || 
                    AuthorizationChecker.isGranted(["MD_READ_PROJECT"]) || 
                    AuthorizationChecker.isGranted(["ECOM_READ_PROJECT"])  
                ))
                {
                    return (
                      <div className="flex-grow-1" style={{ maxWidth: '100%', overflow: 'hidden' }}>
                        <p className="text-muted mb-0">
                          <i className="mdi mdi-calendar me-1"></i>
                          {item.lastComment.createdAt + " "}
                          {item.lastComment.isPrivate ? (
                            <span className="badge rounded-pill bg-danger">
                              {props.t("_PRIVATE_")}
                            </span>
                          ) : ""}
                        </p>
                        <h5 className="font-size-13 mb-1">
                          {item.lastComment.user.firstname} {item.lastComment.user.lastname}
                        </h5>
                        <p className="text-muted mb-1 ms-3" style={{
                          whiteSpace: "pre-wrap",
                          overflowWrap: "break-word",
                          wordBreak: "break-word",
                          maxWidth: "100%",
                        }}>
                          <span dangerouslySetInnerHTML={{ __html: item.lastComment.comments }} />
                        </p>
                      </div>
                    )

                } 
                    
            }

        }
        
    ],
    [unSelectableProjectsIds,items,hiddenColumns,props]
);
    return (
        <div className="page-content">
       
        <Container fluid={true}>
            <LoadingContainer loading={loading} >
                <Card>
                    <CardBody>
                        <div className="mb-4">
                            <Row>
                                <Col xs="12">
                                    <div className="page-title-box justify-content-between">
                                        <div className="">
                                            <ol className="breadcrumb m-0">
                                                <BreadcrumbItem>.</BreadcrumbItem>
                                                <BreadcrumbItem>
                                                    <Link to="#">{props.t("PROJECTS")}</Link>
                                                </BreadcrumbItem>
                                            </ol>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
                <Row className="mb-2">
      <Col md="8" className="d-flex gap-2 align-items-center">
        {AuthorizationChecker.isGranted(["VENDOR_CREATE_PROJECT"]) && 
          <Link className="btn btn-primary" to="/pages-projects/add">{t("ADD_NEW_PROJECT")}</Link>}
        
        {AuthorizationChecker.isGranted(["VENDOR_SEND_TO_MONDOU"]) && 
          <button className="btn btn-primary" disabled={selectedProjectsIds.length === 0} onClick={sendToMondou}>{t("SEND_TO_MONDOU")}</button>}
        
        {AuthorizationChecker.isGranted(["VENDOR_SEND_TO_REN_PET"]) && 
          <button className="btn btn-primary" disabled={selectedProjectsIds.length === 0} onClick={sendToRenPet}>{t("VENDOR_SEND_TO_REN_PET")}</button>}
        
        {AuthorizationChecker.isGranted(["BUYER_EDIT_PROJECT"]) && 
          <button className="btn btn-primary" disabled={selectedProjectsIds.length === 0} onClick={sendBackToVendor}>{t("SEND_BACK_TO_VENDOR")}</button>}
        
        {AuthorizationChecker.isGranted(["BUYER_SENT_TO_DM"]) && 
          <button className="btn btn-primary" disabled={selectedProjectsIds.length === 0} onClick={sendToDM}>{t("SEND_TO_DM")}</button>}
        
        {(AuthorizationChecker.isGranted(["MD_EDIT_PROJECT"]) || AuthorizationChecker.isGranted(["ECOM_READ_PROJECT"])) && 
          <button className="btn btn-primary" disabled={selectedProjectsIds.length === 0} onClick={sendBackToBuyer}>{t("SEND_BACK_TO_PURCHASER")}</button>}
        
        {AuthorizationChecker.isGranted(["MD_EXPORT_FOR_SAP"]) && 
          <button className="btn btn-primary" disabled={selectedProjectsIds.length === 0} onClick={exportForSAP}>{t("EXPORT_SAP")}</button>}
        
        {AuthorizationChecker.isGranted(["BUYER_EXPORT_TO_MICROSOFT"]) && 
          <button className="btn btn-primary" disabled={selectedProjectsIds.length === 0} onClick={exportToMicrosoft}>{t("BUYER_EXPORT_TO_MICROSOFT")}</button>}
        
        {AuthorizationChecker.isGranted(["BUYER_EXPORT_TO_MICROSOFT"]) && companyId === 2 && 
          <button className="btn btn-primary" disabled={selectedProjectsIds.length === 0} onClick={buyerBackToModification}>{t("BACK_TO_MODIFICATION")}</button>}
        
        {AuthorizationChecker.isGranted(["MD_CONFIRM_SAP"]) && 
          <button className="btn btn-primary" disabled={selectedProjectsIds.length === 0} onClick={backToModification}>{t("BACK_TO_MODIFICATION")}</button>}
        
        {AuthorizationChecker.isGranted(["MD_CONFIRM_SAP"]) && 
          <button className="btn btn-primary" disabled={selectedProjectsIds.length === 0} onClick={confirmSapCreation}>{t("CONFIRM_CREATION_SAP")}</button>}
        
        {AuthorizationChecker.isGranted(["ECOM_READ_PROJECT"]) && 
          <button className="btn btn-primary" disabled={selectedProjectsIds.length === 0} onClick={() => exportToEcom(false)}>{t("EXPORT_FOR_ECOM")}</button>}
        
        {AuthorizationChecker.isGranted(["ECOM_READ_PROJECT"]) && 
          <button className="btn btn-primary" disabled={selectedProjectsIds.length === 0} onClick={() => exportToEcom(true)}>{t("END_PROJECT")}</button>}
        
        {AuthorizationChecker.isGranted(["BUYER_EDIT_PROJECT"]) && 
          <button className="btn btn-primary" disabled={selectedProjectsIds.length === 0} onClick={sendToEcom}>{t("SEND_TO_ECOM")}</button>}
      </Col>
    </Row>

                {/* Your JSX code here */}
                <TableContainer  columns={columns}
                            data={items}
                            isGlobalFilter={true}
                            isAddOptions={false}
                            iscustomPageSizeOptions={true}
                            customPageSize={10}
                            isPagination={true}
                            tableClass="align-middle table-nowrap table-check table"
                            theadClass="table-light"
                            paginationDiv="col-12"
                            pagination="justify-content-center pagination pagination-rounded"
                            hiddenColumnsArr ={hiddenColumns} />
                </LoadingContainer>
            </Container>
            <DialogBoxComponent
                handleClose={() => closeDialogModal()}
                handleConfirm={() =>dialog.onConfirm()}
                show={dialog.show}
                type={dialog.type}
                message={dialog.message}
            />
    </div>
    
    );
};
PagesProjectsList.propTypes = {
    t: PropTypes.func
};
export default withTranslation()(PagesProjectsList);