import React, {useEffect} from "react"
import {useParams} from "react-router-dom";
import {
    Container,
} from "reactstrap"

//Import Breadcrumb
//Import Attachment Cards
import {Link} from "react-router-dom";
import {RegisterComponent} from "ppm-react-authentication";
import { withTranslation } from "react-i18next";

import { USERS_API_URL_LG, USERS_COMPANY_API_URL, COMPANY_API_URL} from "../../helpers/api-urls";
const PagesUserAdd = (props) => {
    const {history} = props;
    const params = useParams();
    const {id = 0} = params;


    const onEditSuccess = (response) => {
        history.push(getPagesUrl());
    }

    useEffect(() => { 
    }, []);

    const getPagesUrl = () => {
        return '/pages-users';
    }

    return (
        <React.Fragment>
            <div className="page-content">
                    <title>{props.t("USERS")}</title>
                <Container fluid={true}>
                    <div className="mb-4">
                        <Link className="btn btn-info" to={getPagesUrl()}>{props.t("RETURN_TO_LIST")}</Link>
                    </div>
                    <RegisterComponent 
                         apiUrl={USERS_API_URL_LG} 
                         apiCompanyUrl={COMPANY_API_URL}
                         apiUserCompanyUrl={USERS_COMPANY_API_URL} 
                         useInitials={false}
                        onRegisterSuccess={(u)=>{
                            onEditSuccess();
                        }}
                        
                    />
                </Container>
            </div>
        </React.Fragment>
    )
}
export default withTranslation()(PagesUserAdd)
