/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState,useMemo } from "react"
import PropTypes from 'prop-types';
import {
    Container,
} from "reactstrap"
import LoadingContainer from "../Utility/Helpers/LoadingContainer";
import TableContainer from "../Utility/Helpers/TableContainer";
import {DIALOG_TYPE, HTTP_RESPONSE} from "../Utility/Helpers/constants";
import DialogBoxComponent from "../Utility/Helpers/DialogBoxComponent";
import "../Utility/Helpers/style.css";
import ConfigRepository from "./Repository/config-repository";
import { withTranslation } from "react-i18next";
import UserProvier from "security/UserProvier";
import CellValue from "components/Common/Grid/Widget/CellValue";
import CellEditor from "../../components/Common/Grid/Widget/CellEditor";
import { set } from "lodash";
//import { config } from "dotenv";

const ConfigList = (props) => {

    const [data, setData] = useState([]);
    const [mappedData, setMappedData] = useState([])
    const [loading , setLoading] = useState(false)
    const [isEdited, setIsEdited] = useState(false)
    const [editedData, setEditedData] = useState([])
    const user = UserProvier.get();
    const [originalData,setOriginalData] = useState([])
    const [skipPageReset, setSkipPageReset] = React.useState(false)
    const [dialog, setDialog] = useState({
        show: false,
        onConfirm: () => {
        },
        handleClose: () => {
        },
        message: "",
        type: DIALOG_TYPE.ALERT,
        title: ""
    });

    const closeDialogModal = () => {
        setDialog({...dialog, show: false});
        setLoading(false);
    };

    const catchError = (response) => {
        const currentDialog = {
            show: true,
            onConfirm: () => closeDialogModal(),
            handleClose: () => closeDialogModal(),
            type: DIALOG_TYPE.ALERT
        };
        let status = HTTP_RESPONSE.HTTP_NOT_FOUND;
        if (response.status != undefined) {
            status = response.status;
        } else if (response.error != undefined && response.error.statusCode != undefined) {
            status = response.error.statusCode;
        }
        switch (status) {
            case HTTP_RESPONSE.HTTP_BAD_REQUEST: {
                currentDialog.type = DIALOG_TYPE.ALERT;
                break;
            }
            case HTTP_RESPONSE.HTTP_NOT_FOUND: {
                currentDialog.type = DIALOG_TYPE.ALERT;
                break;
            }
            case HTTP_RESPONSE.HTTP_INTERNAL_SERVER_ERROR: {
                currentDialog.type = DIALOG_TYPE.SERVER_ERROR;
                currentDialog.message = '';
                if (response.data.message) {
                    currentDialog.message = response.data.message;
                }
                break;
            }
            case HTTP_RESPONSE.HTTP_UNAUTHORIZED: {
                dialog.type = DIALOG_TYPE.UNAUTHORIZED;
                dialog.message = response.data.error.message
                break;
            }
            case HTTP_RESPONSE.HTTP_UNPROCESSABLE_ENTITY: {
                currentDialog.type = DIALOG_TYPE.SERVER_ERROR;
                if( response.data.error.code=="ER_DUP_ENTRY"){
                    currentDialog.message = props.t("PROJECT_ALREADY_EXIST");
                }else{
                    currentDialog.message = props.t("CHECK_FORM_FEILDS");
                }
                break;
            }
            default:
        }
        setDialog(currentDialog);
    };

    const handleEditCell = (event, id, field, type) => {
        //change the enableEdit to true for the selected row
        const mappedDataTmp = mappedData.map(config => {
            if(config.id === id){
                config.enableEdit = true
            }
            return config
        });
        setMappedData(mappedDataTmp)
    }

    const updateData = (rowIndex, columnId, value) => {
        
        // We also turn on the flag to not reset the page
        setSkipPageReset(true)
        setMappedData(old =>
          old.map((row, index) => {
            
            if (index === rowIndex) {
              
              return {
                ...old[rowIndex],
                [columnId]: value,
                enableEdit : false
              }
            }
            return row
          })
        )
        //add the edited data to the editedData array

       setIsEdited(true)
      }
    
      // After data chagnes, we turn the flag back off
      // so that if data actually changes when we're not
      // editing it, the page is reset
      useEffect(() => {
        setSkipPageReset(false)
      }, [mappedData])
    
      //reset the data to the original data 
      const resetData = () => {
        setMappedData(originalData)
        setIsEdited(false)
      }
    


      const saveChanges = async () => {
        setLoading(true)
        //before saving the data, we need to filter the data that has been edited, that including the remove enableEdit flag, and the show flag and 
        //and we need to rool back the description to the original value 
        const mappedDataTmp = mappedData.map( (config,index) => {
           
              //remove the enableEdit flag
                delete config.enableEdit
                delete config.show
            //roll back the description to the original value
            config.description = originalData[index].description
            return config
        });
        try {
            const response = await ConfigRepository.updateAll( mappedDataTmp);
            if (response.status ='success') {
                const currentDialog = {
                    show: true,
                    onConfirm: () => closeDialogModal(),
                    handleClose: () => closeDialogModal(),
                    type: DIALOG_TYPE.SUCCESS,
                    title: props.t("CONFIGURATION_UPDATED_SUCCESSFULLY"),
                    message: props.t("NB_UPDATED") +": "+ response.updated
                };
                setDialog(currentDialog);
                //showSucessDialog(props.t("CONFIGURATION_UPDATED_SUCCESSFULLY") + '<BR>' + props.t("NB_UPDATED") +": "+ response.updated )
            }
        } catch (e) {
            catchError(e);
        }
        setLoading(false)
    }

    const handleEditCellValue =  (rowIndex,newValue, field, id, type) => {
        //change the enableEdit to false for the selected row, and update the value
        console.log("newValue", newValue)
        const mappedDataTmp =  mappedData.map(config => {
            if(config.id === id){
                config.enableEdit = false
                config[field] = newValue
            }
           
            return config
        }
        );
        setMappedData(old =>
            old.map((row, index) => {
              if (index === rowIndex) {
                return {
                  ...old[rowIndex],
                  [field]: newValue,
                  enableEdit : false
                }
              }
              return row
            })
          )
        setIsEdited(true)
    }
     

   
    const addEdit = (props) => {
        console.log(props);
        return (
            <Fragment>
                 <CellEditor
                    type="text"
                    field="configValue"
                    disabled={false}
                    //parentId = {this.getparentId(field.columnId, item)}
                    value={props.row.original.configValue}
                    handleCloseUpdate={(e, newValue) => handleEditCellValue(props.row.index, newValue, configValue, props.row.original.id,"text")}
                    handleCloseWithoutSave={(e, newValue) => handleCloseEdit(newValue, configValue, "text")}
                    onUpdated={(newValue) => {}}/>
                </Fragment>
    );
    }

    const displayValue = (props) => {
        console.log("props", props)
        return (
            <Fragment>
                <CellValue
                    type="text"
                    field="configValue"
                    disabled={false}
                    brand={props.row.original.companyId} 
                    //parentId = {this.getparentId(field.columnId, item)}
                    value={props.row.original.configValue}
                    onClick={(event => handleEditCell(event, props.row.original.id, "configValue", "text"))}
                    />
                </Fragment>
    );
    }
    const Cell = (props) => {
        if(props.row.original.enableEdit){
            return addEdit(props)
        }else{
            return displayValue(props)
        }
    }

    
    const columns = useMemo(() => [
        {
            Header: props.t("_ID_"),
            accessor: "id",
            disableFilters: true

        },
        {
            Header: props.t("_KEY_"),
            accessor: "configKey"
        },
        {
            Header: props.t("_VALUE_"),
            accessor: "configValue",
           // Cell: Cell
        },
        {
            Header: props.t("_DESCRIPTION_"),
            accessor: "description",
           
        }
       
    ], [mappedData]);

    const fetch = async () => {
        setLoading(true)
        try {
            const response = await ConfigRepository.findAll(user.companyId);
            console.log(response);
            setData(response);
            //loop through the response and map the data to the table columns
            const mappedDataTmp = response.map(config => {
                return {
                    id: config.id,
                    configKey: config.configKey,
                    configValue: config.configValue,
                    description: props.t(config.description),
                    show : true,
                    enableEdit : false
                }
            });
            setMappedData(mappedDataTmp)
            setOriginalData(response)
            setLoading(false)
        } catch (e) {
            if (e.status === HTTP_RESPONSE.HTTP_NOT_FOUND) {
                setData([]);
            } else {
                console.log(e);
            }
        }
    }

    useEffect(() => {
        fetch();
    }, []);

    const onDelete = async (id) => {
        try {
            await ConfigRepository.delete(id);
            fetch();
        } catch (e) {
            DialogBoxComponent.open(DIALOG_TYPE.ERROR, e.message);
        }
    }

   

    return (
        <Fragment>
            <div className="page-content">
                    <title>{props.t("_CONFIGURATION_")}</title>
                <Container fluid>
                    <div className="row">
                        <div className="col-12 text-right">
                            {isEdited && (
                                <><button className="btn btn-primary" onClick={saveChanges}>{props.t("SAVE")}</button>
                                <button className="btn btn-primary" onClick={resetData}>{props.t("RESET")}</button></>
                            )}
                        </div>
                    </div>
                    <LoadingContainer loading={loading}>
                        <TableContainer columns={columns}
                                data={mappedData}
                                isGlobalFilter={true}
                                isAddOptions={false}
                                iscustomPageSizeOptions={true}
                                customPageSize={50}
                                isPagination={true}
                                tableClass="align-middle table-nowrap table-check table"
                                theadClass="table-light"
                                paginationDiv="col-12"
                                pagination="justify-content-center pagination pagination-rounded" 
                                updateData={updateData}
                                skipPageReset={skipPageReset}
                                cieId={user.companyId}/>
                    </LoadingContainer>
                </Container>
            </div>

            <DialogBoxComponent
                    handleClose={() => closeDialogModal()}
                    handleConfirm={() => dialog.onConfirm()}
                    title={dialog.title}
                    show={dialog.show}
                    type={dialog.type}
                    message={dialog.message}
                />
        </Fragment>
    );
}
ConfigList.propTypes = {
    t: PropTypes.func
}
export default withTranslation()(ConfigList);