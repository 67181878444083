import React, {useEffect,useCallback, useMemo,useState} from "react";

import {UserCompanyRoleListComponent} from "ppm-react-authentication";
import {USERS_COMPANY_ROLES_API_URL, USERS_COMPANY_API_URL} from "../../helpers/api-urls";
import { Link } from "react-router-dom";
import AuthorizationChecker from "security/AuthorizationChecker";
import { withTranslation } from "react-i18next";
import UserCompanyRepository from "./repository/user-company-repository";
import TableContainer from "../Utility/Helpers/TableContainer";
import PropTypes from 'prop-types';
const PagesCompanyUsers = (props) => {

    const [users, setUsers] = useState([]);
    const user = JSON.parse(localStorage.getItem("authUser"));
    const columns = useMemo(() => [
        {
            Header: props.t("_ID_"),
            accessor: "id",
            disableFilters: true,
            id: 'id',
            Cell: (row) => (
                <Link to={`/pages-company-users-roles/${row.value}/edit`}>{row.value}</Link>
            )
        },
        {
            Header: props.t("COMPANY"),
            id: 'company',
            accessor: "company.name",
        },
        {
            Header: props.t("USERS"),
            id: 'users',
            accessor: "user.username",
        },
        {
            Header: props.t("ROLE"),
            id: 'roles',
            
            accessor: "roles",
            Cell: ({ value }) => (
                console.log(value),
                value && value.length > 0
                    ? value.map((role, i) => role.name + (i === value.length - 1 ? "" : " / ")).join('')
                    : props.t("NO_ASSOCIED_ROLE")
            ),
        },
    ], [users, props  ]);

    useEffect(() => {
        let response = UserCompanyRepository.findAllUC(user.companyId);
        response.then((data) => {
            console.log(data);
            setUsers(data.items);
        }
        );
    }, []);

    


    
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    return (
    <>
        <div className="page-content">
                <title>{props.t("COMPANY")} {props.t("USERS")}</title>

            <TableContainer
           columns={columns}
           data={users}
           isGlobalFilter={false}
           isAddOptions={false}
           isCustomPageSize={true}
           customPageSize={10}
           isPagination={true}
           theadClass="table-light"
           paginationDiv="col-12"
           isAddButton={false}
           //handleUserClick={handleCustomerClicks}
           buttonClass="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2 addCustomers-modal"
           buttonName=""
           paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
           tableClass="align-middle table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
           pagination="pagination"
           SearchPlaceholder="search..."
        />

            
        </div>
    </>
    )
}

PagesCompanyUsers.propTypes = {
    t: PropTypes.func
}

export default withTranslation()(PagesCompanyUsers)
