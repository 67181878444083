import React, {useEffect} from "react"
import {useNavigate, useParams} from "react-router-dom";
import {
    Container,
} from "reactstrap"

//Import Breadcrumb
//Import Attachment Cards
import {Link} from "react-router-dom";
import {EditUserComponent} from "ppm-react-authentication";
import {USERS_API_URL_LG} from "../../helpers/api-urls";
import { withTranslation } from "react-i18next";

const PagesUserEdit = (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const {id = 0} = params;

    useEffect(() => {
    }, []);

    /**
     *  Methode appelée quand un dossier est ajouté
     *
     */
    const getPagesUrl = () => {
        return '/pages-users';
    }

    return (
        <React.Fragment>
            <div className="page-content">
                    <title>{props.t("USERS")}</title>
                <Container fluid={true}>
                    <div className="mb-4">
                        <Link className="btn btn-info" to={getPagesUrl()}>{props.t("RETURN_TO_LIST")}</Link>
                    </div>
                    <EditUserComponent
                        id={id}
                        onEditSuccess={()=>{
                            navigate(getPagesUrl());
                        }}
                        apiUrl={USERS_API_URL_LG}
                    />
                </Container>
            </div>
        </React.Fragment>
    )
}
export default withTranslation()(PagesUserEdit)
